<template>
  <div>
    <div class="row">
      <div class="col-xl-12">
        <div class="form-group">
          <label style="margin-bottom: 35px; font-weight: 400"
            >Ilu jest współwłaścicieli?</label
          >
          <div class="form-check-buttons">
            <div class="check">
              <input
                id="coOwners0"
                v-model="form.coOwnersCount"
                name="coOwnersCount"
                type="radio"
                :value="0"
                checked
                @change="handleChangeCoownersCount"
              />
              <label for="coOwners0">0</label>
            </div>
            <div class="check">
              <input
                id="coOwners1"
                v-model="form.coOwnersCount"
                name="coOwnersCount"
                type="radio"
                :value="1"
                checked
                @change="handleChangeCoownersCount"
              />
              <label for="coOwners1">1</label>
            </div>
            <div class="check">
              <input
                id="coOwners2"
                v-model="form.coOwnersCount"
                name="coOwnersCount"
                type="radio"
                :value="2"
                checked
                @change="handleChangeCoownersCount"
              />
              <label for="coOwners2">2</label>
            </div>
            <div class="check">
              <input
                id="coOwners3"
                v-model="form.coOwnersCount"
                name="coOwnersCount"
                type="radio"
                :value="3"
                checked
                @change="handleChangeCoownersCount"
              />
              <label for="coOwners3">3</label>
            </div>
          </div>
          <div v-if="isFieldInvalid('coOwnersCount')" class="invalid-feedback">
            Uzupełnij to pole
          </div>
        </div>
      </div>
    </div>
    <div
      v-for="(coOwner, index) in form.coOwnersArray"
      :key="index"
      class="row mb-5"
    >
      <div class="col-lg-12">
        <h4 class="mb-2 text-secondary">{{ index + 1 }}</h4>
        <ClientForm
          :is-leasing="false"
          :form="coOwner"
          :state="state"
          :policy-type="policyType"
          :v="v.coOwnersArray.$each[index]"
          role="coOwner"
          :disabled="false"
        />
      </div>
    </div>
  </div>
</template>

<script>
import ClientForm from "./form/ClientForm";

export default {
  name: "CoOwners",
  components: { ClientForm },
  props: ["v", "form", "state", "policyType"],
  watch: {
    v: {
      handler() {
        this.$nextTick(() => {
          for (let item in this.v) {
            if (this.v[item].hasOwnProperty("$reset")) {
              if (this.v[item].hasOwnProperty("$each")) {
                for (let each in this.v[item].$each.$iter) {
                  let currentItem = this.v[item].$each[each];
                  for (let item2 in currentItem) {
                    if (currentItem[item2].hasOwnProperty("$reset")) {
                      if (
                        (currentItem[item2].$model !== "" &&
                          currentItem[item2].$model !== null) ||
                        (currentItem[item2].$dirty &&
                          !currentItem[item2].$error &&
                          currentItem[item2].$model === "" &&
                          currentItem[item2].$model === null)
                      ) {
                        currentItem[item2].$reset();
                      }
                    }
                  }
                }
              } else {
                if (
                  (this.v[item].$model !== "" &&
                    this.v[item].$model !== null) ||
                  (this.v[item].$dirty &&
                    !this.v[item].$error &&
                    this.v[item].$model === "" &&
                    this.v[item].$model === null)
                ) {
                  this.v[item].$reset();
                }
              }
            }
          }
        });
      },
      deep: true
    }
  },
  methods: {
    isFieldInvalid(field) {
      return this.v[field].$dirty && this.v[field].$invalid;
    },
    handleChangeCoownersCount(event) {
      this.form.coOwnersCount = Number(event.target.value);
      let newCoowners = [];
      for (let i = 0; i < Number(this.form.coOwnersCount); i++) {
        newCoowners.push({
          isActive: true,
          roles: ["coOwner"],
          legalForm: "",
          withoutPesel: false,
          pesel: "",
          firstName: "",
          lastName: "",
          birthDate: "",
          gender: "",
          phoneNumber: "",
          phoneNumberPrefix: "+48",
          withoutEmail: false,
          email: "",
          companyName: "",
          nip: "",
          regon: ""
        });
      }
      this.form.coOwnersArray = newCoowners;
    }
  }
};
</script>

<style scoped></style>
