<template>
  <div v-if="isOpen">
    <div class="modal-wrapper" :class="variant">
      <div class="modal-overlay" @click="handleClose"></div>

      <div class="modal-inside" :class="size">
        <div v-if="!noHeader" class="heading text-secondary">
          <div class="title">{{ headerTitle }}</div>
        </div>
        <div class="content">
          <div
            v-if="variant === 'info' || hideCloseIcon"
            class="close-icon cursor-pointer"
            @click="handleClose"
          >
            <img src="/media/components/form/form-close.svg" alt="" />
          </div>
          <div
            v-if="variant === 'info'"
            class="info-icon text-center"
            style="margin-bottom: 20px"
          >
            <img :src="`/media/components/form/${infoIcon}.svg`" alt="" />
          </div>
          <slot></slot>
        </div>
        <div v-if="showButtons" class="footer text-end">
          <button
            type="button"
            class="btn"
            :class="cancelVariant"
            @click="handleClose"
          >
            {{ cancelText }}
          </button>
          <button
            type="button"
            class="btn btn-primary-light"
            @click="handleSave"
          >
            {{ saveText }}
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "Modal",
  props: {
    opened: {
      type: Boolean,
      default: false
    },
    showButtons: {
      type: Boolean,
      default: true
    },
    cancelText: {
      type: String,
      default: "Anuluj"
    },
    saveText: {
      type: String,
      default: "Zapisz"
    },
    cancelVariant: {
      type: String,
      default: "btn-outline-cancel"
    },
    noHeader: {
      type: Boolean,
      default: false
    },
    headerTitle: {
      type: String,
      default: ""
    },
    hideCloseIcon: {
      type: Boolean,
      default: false
    },
    size: {
      type: String,
      default: ""
    },
    variant: {
      type: String,
      default: "normal"
    },
    infoIcon: {
      type: String,
      default: "form-approved"
    }
  },
  data() {
    return {
      isOpen: this.opened
    };
  },
  watch: {
    opened(newVal) {
      this.isOpen = newVal;
    }
  },
  methods: {
    handleClose() {
      this.$emit("onClose");
    },
    handleSave() {
      this.$emit("onSave");
    }
  }
};
</script>
