<template>
  <Modal
    size="lg"
    :opened="opened"
    header-title="Rejestracja zgód Rodo"
    @onClose="handleCloseAgreementModal"
    @onSave="handleSaveAgreement"
  >
    <div class="d-flex align-items-center">
      <div>
        <div
          class="form-group small-input-col"
          :class="isFieldInvalid('obtainingMethod') && 'invalid'"
        >
          <Select
            :id="'obtainingMethod-' + index"
            v-model="agreement.obtainingMethod"
            :options="[
              { value: '', text: 'Wybierz opcję' },
              { value: 'callcenter', text: 'CallCenter' },
              { value: 'pismo', text: 'Pismo' }
            ]"
            label="Sposób uzyskania zgód"
            :name="'obtainingMethod-' + index"
          />
          <div
            v-if="isFieldInvalid('obtainingMethod')"
            class="invalid-feedback"
          >
            Uzupełnij to pole
          </div>
        </div>
      </div>
      <div style="margin-left: 50px; margin-top: 5px">
        <div class="check-buttons">
          <div class="check muted">
            <input
              :id="'informationObligation-' + index"
              v-model="agreement.informationObligation"
              type="checkbox"
              name="informationObligation"
            />
            <label :for="'informationObligation-' + index"
              >Klient potwierdził obowiązek informacyjny</label
            >
          </div>
        </div>
      </div>
    </div>
    <div class="form-group" :class="isFieldInvalid('signDate') && 'invalid'">
      <label :for="'signDate' + index">Data podpisania zgody</label>
      <Datepicker
        :id="'signeDate' + index"
        :form="agreement"
        name="signDate"
        :opened="openSignDate && openSignDateIndex === index"
        @openCalendar="handleOpenSignDate"
        @onChange="handleChangeDate($event.target.value, 'signDate', index)"
      />
      <div v-if="isFieldInvalid('signDate')" class="invalid-feedback">
        Uzupełnij to pole
      </div>
    </div>
  </Modal>
</template>

<script>
import { DateTime } from "luxon";
import Modal from "@/view/content/components/modal/Modal";
import Datepicker from "@/view/content/components/date-picker/Datepicker";
import Select from "@/view/content/components/select/Select";
import { CONSTANTS } from "@/core/helpers/Constants";
export default {
  name: "RodoAgreementModal",
  components: { Select, Datepicker, Modal },
  props: {
    agreements: Array,
    agreement: Object,
    index: Number,
    v: Object,
    opened: Boolean,
    handleCloseAgreementModal: Function
  },
  data() {
    return {
      openSignDateIndex: -1,
      openSignDate: false
    };
  },
  watch: {
    v: {
      handler() {
        this.$nextTick(() => {
          for (let item in this.v) {
            if (this.v[item] !== null) {
              if (this.v[item].hasOwnProperty("$reset")) {
                if (this.v[item].hasOwnProperty("$each")) {
                  for (let each in this.v[item].$each.$iter) {
                    let currentItem = this.v[item].$each[each];

                    for (let item2 in currentItem) {
                      if (
                        currentItem[item2] !== null &&
                        currentItem[item2].hasOwnProperty("$reset")
                      ) {
                        if (
                          (currentItem[item2].$model !== "" &&
                            currentItem[item2].$model !== null) ||
                          (currentItem[item2].$dirty &&
                            !currentItem[item2].$error &&
                            currentItem[item2].$model === "" &&
                            currentItem[item2].$model === null)
                        ) {
                          currentItem[item2].$reset();
                        }
                      }
                    }
                  }
                }
              }
            }
          }
        });
      },
      deep: true
    }
  },
  methods: {
    handleChangeDate(value, field, index) {
      this.agreements[index][field] = value
        ? DateTime.fromFormat(value, CONSTANTS().dateYmdFormat).toFormat(
            CONSTANTS().dateYmdFormat
          )
        : "";
    },
    handleResetModal() {
      this.agreement.obtainingMethod = "";
      this.agreement.signDate = "";
      this.agreement.informationObligation = false;
    },
    isFieldInvalid(field) {
      return (
        this.v.agreements.$each[this.index][field].$dirty &&
        this.v.agreements.$each[this.index][field].$invalid
      );
    },
    handleSaveAgreement() {
      this.v.agreements.$each[this.index].$touch();
      if (!this.v.agreements.$each[this.index].$invalid) {
        this.agreements[this.index].status = "accepted";
        this.handleCloseAgreementModal();
      }
    },
    handleOpenSignDate(index) {
      this.openSignDate = true;
      this.openSignDateIndex = index;
    }
  }
};
</script>

<style scoped></style>
