<template>
  <div class="container px-0 h-100">
    <RegisterPolicyView />
  </div>
</template>

<script>
import RegisterPolicyView from "../../content/register-policy/View";
export default {
  name: "RegisterPolicyPageView",
  components: { RegisterPolicyView }
};
</script>
