<template>
  <div>
    <div v-if="role === 'insurer'" class="d-flex">
      <div class="form-group m-0">
        <label
          class="text-secondary"
          style="margin-bottom: 25px; font-weight: 400"
          >Wybierz role klienta na polisie</label
        >
        <div class="check-buttons" style="margin-bottom: 35px">
          <div v-if="role !== 'leasingOwner'" class="check">
            <input
              id="roles-insurer"
              v-model="form.roles"
              type="checkbox"
              name="roles"
              value="insurer"
              disabled
            />
            <label for="roles-insurer">Ubezpieczający</label>
          </div>
          <div
            v-if="
              policyType === constantsPolicyTypeLife ||
                policyType === constantsPolicyTypeProperty
            "
            class="check"
          >
            <input
              id="roles-insured"
              v-model="form.roles"
              type="checkbox"
              name="roles"
              value="insured"
              :disabled="role === 'insured'"
              @change="handleChangeRole($event)"
            />
            <label for="roles-insured">Ubezpieczony</label>
          </div>
          <div
            v-if="
              policyType === constantsPolicyTypeAuto &&
                clientState.isLeasing &&
                role !== 'leasingOwner'
            "
            class="check"
          >
            <input
              id="roles-user"
              v-model="form.roles"
              type="checkbox"
              name="roles"
              value="user"
              :disabled="role === 'user'"
              @change="handleChangeRole($event)"
            />
            <label for="roles-user">Użytkownik</label>
          </div>
          <div
            v-if="
              policyType === constantsPolicyTypeAuto && !clientState.isLeasing
            "
            class="check"
          >
            <input
              id="roles-owner"
              v-model="form.roles"
              type="checkbox"
              name="roles"
              value="owner"
              :disabled="role === 'owner'"
              @change="handleChangeRole($event)"
            />
            <label for="roles-owner">Właściciel</label>
          </div>
        </div>
      </div>
      <div
        v-if="policyType === constantsPolicyTypeAuto"
        class="check-buttons"
        style="margin-left: 50px"
      >
        <div class="check muted">
          <input
            id="isLeasing"
            v-model="clientState.isLeasing"
            name="isLeasing"
            type="checkbox"
          />
          <label for="isLeasing">LEASING</label>
        </div>
      </div>
    </div>
    <div class="row">
      <div class="col-lg-6">
        <div
          class="form-group"
          :class="
            form.legalForm === '' &&
              'mb-0' &&
              isFieldInvalid('legalForm') &&
              'invalid'
          "
        >
          <Select
            id="legalForm"
            v-model="form.legalForm"
            :options="legalForms"
            label="Forma prawna"
            name="legalForm"
          />
          <div v-if="isFieldInvalid('legalForm')" class="invalid-feedback">
            Uzupełnij to pole
          </div>
        </div>
      </div>
    </div>
    <keep-alive>
      <template
        v-if="
          form.legalForm === 'company' || form.legalForm === 'onePersonCompany'
        "
      >
        <ClientCompanyForm :form="form" :state="state" :v="v" :role="role" />
      </template>
      <template v-else-if="form.legalForm === 'private'">
        <ClientPrivateForm :form="form" :state="state" :v="v" :role="role" />
      </template>
    </keep-alive>
    <template v-if="policyType === constantsPolicyTypeLife">
      <template
        v-if="
          role === 'insurer' && !clientForm.insurer.roles.includes('insured')
        "
      >
        <div
          class="d-flex align-items-center cursor-pointer"
          style="margin-top: 30px"
          @click="handleAddChildrenToPolicy"
        >
          <div style="margin-right: 15px; font-size: 15px">
            Chcę dodać dzieci do polisy
          </div>
          <div class="dropdown-icon">
            <img src="/media/components/form/form-add-element.svg" alt="" />
          </div>
        </div>
      </template>
      <template
        v-if="
          role === 'insured' || clientForm.insurer.roles.includes('insured')
        "
      >
        <div class="children"></div>
        <ClientChildrenForm
          :v="vInsured"
          :form="clientForm"
          :state="clientState"
          :policy-type="policyType"
          :handle-set-current-step="handleSetCurrentStep"
        />
      </template>
    </template>
  </div>
</template>

<script>
import { scrollToElement } from "@/core/helpers/ScrollToElement";
import {
  INITIAL_FORM_DATA,
  INITIAL_FORM_STATE
} from "@/core/helpers/PolicyFormState";
import ClientCompanyForm from "@/view/content/register-policy/steps/client/form/ClientCompanyForm";
import ClientPrivateForm from "@/view/content/register-policy/steps/client/form/ClientPrivateForm";
import ClientChildrenForm from "@/view/content/register-policy/steps/client/form/ClientChildrenForm";
import Select from "@/view/content/components/select/Select";
import { CONSTANTS } from "@/core/helpers/Constants";
export default {
  name: "ClientForm",
  components: {
    Select,
    ClientChildrenForm,
    ClientPrivateForm,
    ClientCompanyForm
  },
  props: [
    "policyType",
    "role",
    "clientState",
    "state",
    "insuredState",
    "clientForm",
    "form",
    "handleSetCurrentStep",
    "v",
    "vInsured"
  ],
  data() {
    return {
      openPhonePrefix: false,
      legalForms: [
        { text: "Wybierz opcję", value: "" },
        { text: "osoba fizyczna", value: "private" },
        { text: "1-os. działalność gosp.", value: "onePersonCompany" },
        { text: "spółka", value: "company" }
      ]
    };
  },
  computed: {
    constantsPolicyTypeAuto() {
      return CONSTANTS().policyTypeAuto;
    },
    constantsPolicyTypeProperty() {
      return CONSTANTS().policyTypeProperty;
    },
    constantsPolicyTypeLife() {
      return CONSTANTS().policyTypeLife;
    }
  },
  watch: {
    v: {
      handler() {
        this.$nextTick(() => {
          for (let item in this.v) {
            if (this.v[item].hasOwnProperty("$reset")) {
              if (this.v[item].hasOwnProperty("$each")) {
                for (let each in this.v[item].$each.$iter) {
                  let currentItem = this.v[item].$each[each];
                  for (let item2 in currentItem) {
                    if (currentItem[item2].hasOwnProperty("$reset")) {
                      if (
                        (currentItem[item2].$model !== "" &&
                          currentItem[item2].$model !== null) ||
                        (currentItem[item2].$dirty &&
                          !currentItem[item2].$error &&
                          currentItem[item2].$model === "" &&
                          currentItem[item2].$model === null)
                      ) {
                        currentItem[item2].$reset();
                      }
                    }
                  }
                }
              } else {
                if (
                  (this.v[item].$model !== "" &&
                    this.v[item].$model !== null) ||
                  (this.v[item].$dirty &&
                    !this.v[item].$error &&
                    this.v[item].$model === "" &&
                    this.v[item].$model === null)
                ) {
                  this.v[item].$reset();
                }
              }
            }
          }
        });
      },
      deep: true
    }
  },
  methods: {
    resetClient() {
      this.form.gender = "";
      this.form.pesel = "";
      this.form.firstName = "";
      this.form.lastName = "";
      this.form.birthDate = "";

      this.form.regon = "";
      this.form.companyName = "";
      this.form.nip = "";

      this.form.phoneNumber = "";
      this.form.email = "";

      this.state.isLoaded = null;
    },
    isFieldInvalid(field) {
      return this.v[field].$dirty && this.v[field].$invalid;
    },
    handleChangeRole(event) {
      const { checked, value } = event.target;
      if (checked) {
        this.clientForm[value] = { ...INITIAL_FORM_DATA().client[value] };
        this.clientState[value] = { ...INITIAL_FORM_STATE().client[value] };
        this.clientForm[value].isActive = false;
      } else {
        this.clientForm[value].isActive = true;
      }
    },
    handleChangeLegalForm() {
      this.resetClient();
    },
    handleAddChildrenToPolicy() {
      this.insuredState.hasChildren = true;
      this.handleSetCurrentStep("insured");
      if (this.clientForm.insured.children.length < 1) {
        this.handleAddChildren();
      }
      this.$nextTick(() => {
        scrollToElement(".children");
      });
    },
    handleAddChildren() {
      if (this.policyType === this.constantsPolicyTypeLife) {
        this.clientForm.insured.children.push({
          birthDate: "",
          firstName: "",
          lastName: ""
        });
      }
    }
  }
};
</script>
