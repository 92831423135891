<template>
  <div class="step-nav">
    <div
      class="nav-step"
      :class="isCurrentStep(1) && 'active-step'"
      @click="handleChangeStep(1)"
    >
      <div class="step-number">
        <template v-if="state.basic.formState">
          <img src="/media/components/steps/steps-nav-accept.svg" alt="" />
        </template>
        <template v-else>1.</template>
      </div>
      <div class="step-name">Dane podstawowe</div>
    </div>
    <div
      class="nav-step"
      :class="isCurrentStep(2) && 'active-step'"
      @click="handleChangeStep(2)"
    >
      <div class="step-number">
        <template v-if="state.policy.formState">
          <img src="/media/components/steps/steps-nav-accept.svg" alt="" />
        </template>
        <template v-else>2.</template>
      </div>
      <div class="step-name">Dane polisy</div>
    </div>
    <div
      class="nav-step"
      :class="isCurrentStep(3) && 'active-step'"
      @click="handleChangeStep(3)"
    >
      <div class="step-number">
        <template v-if="state.client.formState">
          <img src="/media/components/steps/steps-nav-accept.svg" alt="" />
        </template>
        <template v-else>3.</template>
      </div>
      <div class="step-name">Klient</div>
    </div>
    <div
      class="nav-step"
      :class="isCurrentStep(4) && 'active-step'"
      @click="handleChangeStep(4)"
    >
      <div class="step-number">
        <template v-if="state.rodo.formState">
          <img src="/media/components/steps/steps-nav-accept.svg" alt="" />
        </template>
        <template v-else>4.</template>
      </div>
      <div class="step-name">Zgody RODO</div>
    </div>
    <div
      v-show="form.basic.type !== constantsPolicyTypeLife && form.basic.productType !== 'inne'"
      class="nav-step"
      :class="isCurrentStep(5) && 'active-step'"
      @click="handleChangeStep(5)"
    >
      <div class="step-number">
        <template v-if="state.insuranceObject.formState">
          <img src="/media/components/steps/steps-nav-accept.svg" alt="" />
        </template>
        <template v-else>5.</template>
      </div>
      <div class="step-name">Przedmiot ubezpieczenia</div>
    </div>
    <div
      class="nav-step"
      :class="isCurrentStep(6) && 'active-step'"
      @click="handleChangeStep(6)"
    >
      <div class="step-number">
        <template v-if="state.idd.formState">
          <img src="/media/components/steps/steps-nav-accept.svg" alt="" />
        </template>
        <template v-else>6.</template>
      </div>
      <div class="step-name">IDD</div>
    </div>
  </div>
</template>

<script>
import {CONSTANTS} from "@/core/helpers/Constants";

export default {
  name: "StepNav",
  props: {
    state: {
      type: Object,
      default: null
    },
    form: {
      type: Object,
      default: null
    },
    handleChangeStep: {
      type: Function,
      default: null
    },
    isCurrentStep: {
      type: Function,
      default: null
    }
  },
  computed: {
    constantsPolicyTypeAuto() {
      return CONSTANTS().policyTypeAuto;
    },
    constantsPolicyTypeProperty() {
      return CONSTANTS().policyTypeProperty;
    },
    constantsPolicyTypeLife() {
      return CONSTANTS().policyTypeLife;
    },
  }
};
</script>
