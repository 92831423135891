var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[(_vm.role === 'insurer')?_c('div',{staticClass:"d-flex"},[_c('div',{staticClass:"form-group m-0"},[_c('label',{staticClass:"text-secondary",staticStyle:{"margin-bottom":"25px","font-weight":"400"}},[_vm._v("Wybierz role klienta na polisie")]),_c('div',{staticClass:"check-buttons",staticStyle:{"margin-bottom":"35px"}},[(_vm.role !== 'leasingOwner')?_c('div',{staticClass:"check"},[_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.form.roles),expression:"form.roles"}],attrs:{"id":"roles-insurer","type":"checkbox","name":"roles","value":"insurer","disabled":""},domProps:{"checked":Array.isArray(_vm.form.roles)?_vm._i(_vm.form.roles,"insurer")>-1:(_vm.form.roles)},on:{"change":function($event){var $$a=_vm.form.roles,$$el=$event.target,$$c=$$el.checked?(true):(false);if(Array.isArray($$a)){var $$v="insurer",$$i=_vm._i($$a,$$v);if($$el.checked){$$i<0&&(_vm.$set(_vm.form, "roles", $$a.concat([$$v])))}else{$$i>-1&&(_vm.$set(_vm.form, "roles", $$a.slice(0,$$i).concat($$a.slice($$i+1))))}}else{_vm.$set(_vm.form, "roles", $$c)}}}}),_c('label',{attrs:{"for":"roles-insurer"}},[_vm._v("Ubezpieczający")])]):_vm._e(),(
            _vm.policyType === _vm.constantsPolicyTypeLife ||
              _vm.policyType === _vm.constantsPolicyTypeProperty
          )?_c('div',{staticClass:"check"},[_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.form.roles),expression:"form.roles"}],attrs:{"id":"roles-insured","type":"checkbox","name":"roles","value":"insured","disabled":_vm.role === 'insured'},domProps:{"checked":Array.isArray(_vm.form.roles)?_vm._i(_vm.form.roles,"insured")>-1:(_vm.form.roles)},on:{"change":[function($event){var $$a=_vm.form.roles,$$el=$event.target,$$c=$$el.checked?(true):(false);if(Array.isArray($$a)){var $$v="insured",$$i=_vm._i($$a,$$v);if($$el.checked){$$i<0&&(_vm.$set(_vm.form, "roles", $$a.concat([$$v])))}else{$$i>-1&&(_vm.$set(_vm.form, "roles", $$a.slice(0,$$i).concat($$a.slice($$i+1))))}}else{_vm.$set(_vm.form, "roles", $$c)}},function($event){return _vm.handleChangeRole($event)}]}}),_c('label',{attrs:{"for":"roles-insured"}},[_vm._v("Ubezpieczony")])]):_vm._e(),(
            _vm.policyType === _vm.constantsPolicyTypeAuto &&
              _vm.clientState.isLeasing &&
              _vm.role !== 'leasingOwner'
          )?_c('div',{staticClass:"check"},[_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.form.roles),expression:"form.roles"}],attrs:{"id":"roles-user","type":"checkbox","name":"roles","value":"user","disabled":_vm.role === 'user'},domProps:{"checked":Array.isArray(_vm.form.roles)?_vm._i(_vm.form.roles,"user")>-1:(_vm.form.roles)},on:{"change":[function($event){var $$a=_vm.form.roles,$$el=$event.target,$$c=$$el.checked?(true):(false);if(Array.isArray($$a)){var $$v="user",$$i=_vm._i($$a,$$v);if($$el.checked){$$i<0&&(_vm.$set(_vm.form, "roles", $$a.concat([$$v])))}else{$$i>-1&&(_vm.$set(_vm.form, "roles", $$a.slice(0,$$i).concat($$a.slice($$i+1))))}}else{_vm.$set(_vm.form, "roles", $$c)}},function($event){return _vm.handleChangeRole($event)}]}}),_c('label',{attrs:{"for":"roles-user"}},[_vm._v("Użytkownik")])]):_vm._e(),(
            _vm.policyType === _vm.constantsPolicyTypeAuto && !_vm.clientState.isLeasing
          )?_c('div',{staticClass:"check"},[_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.form.roles),expression:"form.roles"}],attrs:{"id":"roles-owner","type":"checkbox","name":"roles","value":"owner","disabled":_vm.role === 'owner'},domProps:{"checked":Array.isArray(_vm.form.roles)?_vm._i(_vm.form.roles,"owner")>-1:(_vm.form.roles)},on:{"change":[function($event){var $$a=_vm.form.roles,$$el=$event.target,$$c=$$el.checked?(true):(false);if(Array.isArray($$a)){var $$v="owner",$$i=_vm._i($$a,$$v);if($$el.checked){$$i<0&&(_vm.$set(_vm.form, "roles", $$a.concat([$$v])))}else{$$i>-1&&(_vm.$set(_vm.form, "roles", $$a.slice(0,$$i).concat($$a.slice($$i+1))))}}else{_vm.$set(_vm.form, "roles", $$c)}},function($event){return _vm.handleChangeRole($event)}]}}),_c('label',{attrs:{"for":"roles-owner"}},[_vm._v("Właściciel")])]):_vm._e()])]),(_vm.policyType === _vm.constantsPolicyTypeAuto)?_c('div',{staticClass:"check-buttons",staticStyle:{"margin-left":"50px"}},[_c('div',{staticClass:"check muted"},[_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.clientState.isLeasing),expression:"clientState.isLeasing"}],attrs:{"id":"isLeasing","name":"isLeasing","type":"checkbox"},domProps:{"checked":Array.isArray(_vm.clientState.isLeasing)?_vm._i(_vm.clientState.isLeasing,null)>-1:(_vm.clientState.isLeasing)},on:{"change":function($event){var $$a=_vm.clientState.isLeasing,$$el=$event.target,$$c=$$el.checked?(true):(false);if(Array.isArray($$a)){var $$v=null,$$i=_vm._i($$a,$$v);if($$el.checked){$$i<0&&(_vm.$set(_vm.clientState, "isLeasing", $$a.concat([$$v])))}else{$$i>-1&&(_vm.$set(_vm.clientState, "isLeasing", $$a.slice(0,$$i).concat($$a.slice($$i+1))))}}else{_vm.$set(_vm.clientState, "isLeasing", $$c)}}}}),_c('label',{attrs:{"for":"isLeasing"}},[_vm._v("LEASING")])])]):_vm._e()]):_vm._e(),_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-lg-6"},[_c('div',{staticClass:"form-group",class:_vm.form.legalForm === '' &&
            'mb-0' &&
            _vm.isFieldInvalid('legalForm') &&
            'invalid'},[_c('Select',{attrs:{"id":"legalForm","options":_vm.legalForms,"label":"Forma prawna","name":"legalForm"},model:{value:(_vm.form.legalForm),callback:function ($$v) {_vm.$set(_vm.form, "legalForm", $$v)},expression:"form.legalForm"}}),(_vm.isFieldInvalid('legalForm'))?_c('div',{staticClass:"invalid-feedback"},[_vm._v(" Uzupełnij to pole ")]):_vm._e()],1)])]),_c('keep-alive',[(
        _vm.form.legalForm === 'company' || _vm.form.legalForm === 'onePersonCompany'
      )?[_c('ClientCompanyForm',{attrs:{"form":_vm.form,"state":_vm.state,"v":_vm.v,"role":_vm.role}})]:(_vm.form.legalForm === 'private')?[_c('ClientPrivateForm',{attrs:{"form":_vm.form,"state":_vm.state,"v":_vm.v,"role":_vm.role}})]:_vm._e()],2),(_vm.policyType === _vm.constantsPolicyTypeLife)?[(
        _vm.role === 'insurer' && !_vm.clientForm.insurer.roles.includes('insured')
      )?[_c('div',{staticClass:"d-flex align-items-center cursor-pointer",staticStyle:{"margin-top":"30px"},on:{"click":_vm.handleAddChildrenToPolicy}},[_c('div',{staticStyle:{"margin-right":"15px","font-size":"15px"}},[_vm._v(" Chcę dodać dzieci do polisy ")]),_vm._m(0)])]:_vm._e(),(
        _vm.role === 'insured' || _vm.clientForm.insurer.roles.includes('insured')
      )?[_c('div',{staticClass:"children"}),_c('ClientChildrenForm',{attrs:{"v":_vm.vInsured,"form":_vm.clientForm,"state":_vm.clientState,"policy-type":_vm.policyType,"handle-set-current-step":_vm.handleSetCurrentStep}})]:_vm._e()]:_vm._e()],2)}
var staticRenderFns = [function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"dropdown-icon"},[_c('img',{attrs:{"src":"/media/components/form/form-add-element.svg","alt":""}})])}]

export { render, staticRenderFns }