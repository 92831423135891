export const collapseSection = element => {
  // get the height of the element's inner content, regardless of its actual size
  const sectionHeight = element.scrollHeight;

  // temporarily disable all css transitions
  const elementTransition = element.style.transition;
  element.style.transition = "";

  // on the next frame (as soon as the previous style change has taken effect),
  // explicitly set the element's height to its current pixel height, so we
  // aren't transitioning out of 'auto'
  requestAnimationFrame(function() {
    element.style.maxHeight = sectionHeight + "px";
    element.style.transition = elementTransition;

    // on the next frame (as soon as the previous style change has taken effect),
    // have the element transition to height: 0
    requestAnimationFrame(function() {
      element.style.maxHeight = 0 + "px";
    });
  });
};

export const updateSection = element => {
  if (element) {
    let content = element.querySelector(".collapsed-content");
    content.style.maxHeight = "100%";
  }
};

export const expandSection = element => {
  // get the height of the element's inner content, regardless of its actual size
  const sectionHeight = element.scrollHeight - 20;

  // have the element transition to the height of its inner content
  element.style.maxHeight = sectionHeight + "px";
  let child = element.querySelector(".collapse-wrapper .collapsed-content");
  if (child) {
    const childHeight = child.scrollHeight;
    element.style.maxHeight = sectionHeight + childHeight + "px";
  }
};
