<template>
  <div>
    <tabs>
      <tab
        id="issue"
        :is-active="state.activeTab === 'issue'"
        title="Sprawa"
        :change="handleSetCurrentStep"
      >
        <Modal
          size="xs"
          variant="info"
          info-icon="form-approved"
          :opened="isCreateIssueModalVisible"
          no-header
          :show-buttons="false"
          @onClose="handleCloseCreateIssueModal"
        >
          <p class="m-0 text-center" style="line-height: 2">
            Dla tej polisy utworzyliśmy sprawę numer: 678906<br />
            Znajdziesz ją w swoim profilu, w zakładce "Sprawy".
          </p>
        </Modal>
        <p style="margin-bottom: 25px">
          Czy masz utworzoną sprawę dla tej polisy?
        </p>
        <div class="d-flex align-items-center" style="margin-bottom: 30px">
          <div class="dev-check-buttons mb-0">
            <button
              class="btn btn-primary-light"
              type="button"
              @click="
                state.issue.isLoaded = true;
                form.issue.issueId = '';
              "
            >
              Dev: Znaleziono jakies sprawy
            </button>
            <button
              class="btn btn-primary-light"
              type="button"
              @click="
                state.issue.isLoaded = false;
                form.issue.issueId = '12121';
              "
            >
              Dev: Nie znaleziono spraw
            </button>
          </div>
          <div
            v-if="state.issue.isLoaded === true"
            class="d-flex align-items-center"
          >
            <div class="icon">
              <img src="/media/components/form/form-list.svg" alt="" />
            </div>
            <div class="text text-secondary" style="margin-left: 10px">
              <p class="mb-0">
                Przypisz sprawę z listy lub utwórz nową sprawę.
              </p>
            </div>
          </div>
        </div>
        <div v-if="state.issue.isLoaded === false" style="margin-top: 40px">
          <div class="d-flex align-items-center">
            <div>
              <img src="/media/components/form/form-info.svg" alt="" />
            </div>
            <div style="margin-left: 15px">
              <p class="text-secondary m-0" style="line-height: 25px">
                Nie znaleziono spraw dla tego klienta. Dla tej polisy<br />
                utworzyliśmy automatycznie sprawę numer: 678906 Znajdziesz ją<br />
                w swoim profilu, w zakładce "Sprawy".
              </p>
            </div>
          </div>
        </div>
        <div v-if="state.issue.isLoaded === true" style="margin-top: 50px">
          <CheckTable
            :items="issues"
            title=""
            title-style="mb-0"
            :v="v.issue"
            :validate-state="true"
            field="issueId"
            variant="no-border"
          />
          <div class="text-end" style="margin-top: 20px">
            <button
              type="button"
              class="btn btn-outline-primary"
              @click="handleOpenCreateIssueModal"
            >
              Utwórz nową sprawę
            </button>
          </div>
        </div>
      </tab>
      <tab
        id="statement"
        :is-active="state.activeTab === 'statement'"
        title="Oświadczenie IDD"
        :change="handleSetCurrentStep"
      >
        <Modal
          size="lg"
          :opened="isAddScanModalVisible"
          header-title="Wgrywanie skanu IDD"
          @onClose="isAddScanModalVisible = false"
          @onSave="handleAddScan"
        >
          <div
            class="
              custom-file-upload-group
              d-flex
              align-items-center
              justify-content-center
            "
          >
            <button
              type="button"
              style="margin-right: 20px"
              class="btn btn-outline-primary-light"
            >
              Wybierz plik
            </button>
            <input
              type="text"
              class="form-control"
              name="scan-file-name"
              readonly
            />
            <input id="scanFile" type="file" name="scanFile" />
          </div>
        </Modal>
        <div class="dev-check-buttons">
          <button
            class="btn btn-primary-light"
            type="button"
            @click="handleFillIDDStatement"
          >
            Dev: Pobrano IDD
          </button>
          <button
            class="btn btn-primary-light"
            type="button"
            @click="handleResetIDDStatement"
          >
            Dev: Dodaj IDD
          </button>
        </div>
        <fieldset :disabled="isFormDisabled">
          <template v-if="state.statement.isLoaded">
            <p
              class="text-secondary font-italic"
              style="margin-bottom: 30px; line-height: 24px"
            >
              Dla tej polisy pobraliśmy oświadczenie IDD ze sprawy nr 67892.
            </p>
            <table class="table table-custom-2">
              <tbody>
                <tr>
                  <td style="width: 180px">
                    Sposób pracy<br />
                    z klientem
                  </td>
                  <td>
                    {{ form.statement.workWithClientType }}
                  </td>
                </tr>
                <tr>
                  <td style="width: 180px">
                    APK -Analiza<br />
                    Potrzeb klienta
                  </td>
                  <td>
                    {{ form.statement.apk }}
                  </td>
                </tr>
                <tr>
                  <td style="width: 180px">Rodzaj<br />oświadczenia</td>
                  <td>
                    {{ getTranslatedStatementType }}
                  </td>
                </tr>
                <tr>
                  <td style="width: 180px">Data spotkania<br />/rozmowy</td>
                  <td>
                    {{ form.statement.meetingDate }}
                  </td>
                </tr>
                <tr>
                  <td style="width: 180px">Status</td>
                  <td>
                    <div class="d-flex align-items-center">
                      <div :class="getStatusColor" v-html="getTranslatedStatus">
                        {{ getTranslatedStatus }}
                      </div>
                      <div
                        v-if="form.statement.status === 'verifing'"
                        class="rounded-status"
                        style="margin-left: 60px"
                      >
                        trwa weryfikacja
                      </div>
                      <button
                        v-if="form.statement.status === 'verifing'"
                        type="button"
                        class="btn btn-sm btn-warning"
                        style="margin-left: 30px"
                        @click="handleVerifyScan"
                      >
                        Zweryfikuj
                      </button>
                      <button
                        v-if="
                          form.statement.status ===
                            'waitingForClientConfirmation'
                        "
                        type="button"
                        class="btn btn-sm btn-warning"
                        style="margin-left: 30px"
                        @click="handleConfirmByClient"
                      >
                        Potwierdź przez klienta
                      </button>
                    </div>
                  </td>
                  <td
                    v-if="
                      form.statement.status === 'waitingForClientConfirmation'
                    "
                  ></td>
                </tr>
              </tbody>
            </table>
            <div class="d-flex align-items-center" style="margin-top: 30px">
              <template
                v-if="
                  form.statement.status !== 'waitingForClientConfirmation' &&
                    form.statement.status !== 'confirmedByClient'
                "
              >
                <button
                  type="button"
                  class="btn btn-outline-primary"
                  @click="handleGenerateIDD"
                >
                  Generuj IDD
                  <i
                    v-if="isGeneratingIDD"
                    class="fa fa-spin fa-spinner ms-2"
                  ></i>
                </button>
                <button
                  v-if="form.statement.scanFile !== null"
                  type="button"
                  class="btn btn-outline-primary"
                  style="margin-left: 30px"
                  @click="handleDownloadScan"
                >
                  Pobierz skan
                  <i
                    v-if="isDownloadingScan"
                    class="fa fa-spin fa-spinner ms-2"
                  ></i>
                </button>
                <button
                  type="button"
                  class="btn btn-outline-primary"
                  style="margin-left: 30px"
                  :disabled="form.statement.scanFile !== null"
                  @click="handleOpenUploadScanModal"
                >
                  Wgraj skan
                </button>
              </template>
              <template
                v-if="form.statement.status === 'waitingForClientConfirmation'"
              >
                <button
                  type="button"
                  class="btn btn-outline-primary"
                  :disabled="isSendingEmailAgain"
                  @click="handleSendEmailAgain"
                >
                  Wyślij ponownie
                  <i
                    v-if="isSendingEmailAgain"
                    class="fa fa-spin fa-spinner ms-2"
                  ></i>
                </button>
              </template>
            </div>
          </template>
          <template v-else>
            <p
              class="text-secondary font-italic"
              style="margin-bottom: 30px; line-height: 24px"
            >
              Wypełnij formularz, aby zarejestrować oświadczenie IDD do
              polisy.<br />
              Możesz wybrać wersję online lub druk z podpisem klienta.
            </p>
            <div class="row">
              <div class="col-lg-6">
                <div class="form-group">
                  <Select
                    id="workWithClientType"
                    v-model="form.statement.workWithClientType"
                    :options="workWithClientTypes"
                    label="Sposób pracy z klientem"
                    name="workWithClientType"
                  />
                  <div
                    v-if="isStatementFieldInvalid('workWithClientType')"
                    class="invalid-feedback"
                  >
                    Uzupełnij to pole
                  </div>
                </div>
              </div>
            </div>
            <div class="row">
              <div class="col-lg-6">
                <div class="form-group">
                  <label for="meetingDate">Data spotkania/rozmowy</label>
                  <Datepicker
                    id="meetingDate"
                    :form="form.statement"
                    name="meetingDate"
                    @onChange="handleChangeDate($event, 'meetingDate')"
                  />
                  <div
                    v-if="isStatementFieldInvalid('meetingDate')"
                    class="invalid-feedback"
                  >
                    Uzupełnij to pole
                  </div>
                </div>
              </div>
            </div>
            <div class="row">
              <div class="col-lg-6">
                <div class="form-group">
                  <Select
                    id="apk"
                    v-model="form.statement.apk"
                    :options="apks"
                    label="APK -Analiza Potrzeb klienta"
                    name="apk"
                  />
                  <div
                    v-if="isStatementFieldInvalid('apk')"
                    class="invalid-feedback"
                  >
                    Uzupełnij to pole
                  </div>
                </div>
              </div>
            </div>
            <div class="row">
              <div class="col-lg-6">
                <div class="form-group mb-0">
                  <Select
                    id="type"
                    v-model="form.statement.type"
                    :options="statementTypes"
                    label="Rodzaj oświadczenia"
                    name="type"
                  />

                  <div
                    v-if="isStatementFieldInvalid('type')"
                    class="invalid-feedback"
                  >
                    Uzupełnij to pole
                  </div>
                </div>
              </div>
            </div>
            <template v-if="form.statement.type === 'print'">
              <div class="d-flex align-items-center" style="margin-top: 30px">
                <button
                  type="button"
                  class="btn btn-primary-light"
                  @click="handleGenerateIDD"
                >
                  Generuj IDD
                  <i
                    v-if="isGeneratingIDD"
                    class="fa fa-spin fa-spinner ms-2"
                  ></i>
                </button>
                <button
                  v-if="form.statement.scanFile !== null"
                  type="button"
                  class="btn btn-outline-primary"
                  style="margin-left: 30px"
                  @click="handleDownloadScan"
                >
                  Pobierz skan
                  <i
                    v-if="isDownloadingScan"
                    class="fa fa-spin fa-spinner ms-2"
                  ></i>
                </button>
                <button
                  type="button"
                  class="btn btn-outline-primary"
                  style="margin-left: 30px"
                  :disabled="form.statement.scanFile !== null"
                  @click="handleOpenUploadScanModal"
                >
                  Wgraj skan
                </button>
                <p
                  v-if="form.statement.scanFile === null"
                  style="margin-left: 20px"
                  class="mb-0 text-secondary font-italic"
                >
                  Skan IDD możesz wgrać później (po rejestracji polisy)
                </p>
              </div>
            </template>
            <template v-else-if="form.statement.type === 'online'">
              <Modal
                size="lg"
                :opened="isSendEmailModalVisible"
                no-header
                @onClose="isSendEmailModalVisible = false"
                @onSave="handleSendEmail"
              >
                <p class="text-center m-0" style="line-height: 30px">
                  Klient otrzyma wiadomość z linkiem do oświadczenia IDD<br />
                  e-mail <strong>dummy@gmail.com</strong><br />
                  SMS 535 152 785
                </p>
              </Modal>
              <div class="d-flex align-items-center" style="margin-top: 30px">
                <button
                  type="button"
                  class="btn btn-primary-light"
                  @click="handleOpenSendEmailModal"
                >
                  Wyślij e-mail
                  <i
                    v-if="isSendingEmail"
                    class="fa fa-spin fa-spinner ms-2"
                  ></i>
                </button>
              </div>
            </template>
          </template>
        </fieldset>
      </tab>
    </tabs>
  </div>
</template>

<script>
import { DateTime } from "luxon";
import { CONSTANTS } from "@/core/helpers/Constants";
import Tabs from "@/view/content/components/tabs/Tabs";
import Tab from "@/view/content/components/tabs/Tab";
import Modal from "@/view/content/components/modal/Modal";
import { scrollToElement } from "@/core/helpers/ScrollToElement";
import CheckTable from "@/view/content/components/table/CheckTable";
import Datepicker from "@/view/content/components/date-picker/Datepicker";
import Select from "@/view/content/components/select/Select";

export default {
  name: "IDD",
  components: { Select, CheckTable, Tabs, Tab, Modal, Datepicker },
  props: ["v", "form", "state", "client"],
  data() {
    return {
      statementTypes: [
        { value: "", text: "Wybierz opcję" },
        { value: "print", text: "Druk z podpisem" },
        {
          value: "online",
          text: "Online-wyślij mail do klienta"
        }
      ],
      workWithClientTypes: [
        { value: "", text: "Wybierz opcję" },
        {
          text: "Bezpośrednio - w oddziale/u klienta",
          value: "Bezpośrednio - w oddziale/u klienta"
        }
      ],
      apks: [
        { value: "", text: "Wybierz opcję" },
        {
          text: "APK OK - Klient wybrał produkt z rekomendacji",
          value: "APK OK - Klient wybrał produkt z rekomendacji"
        }
      ],
      isGeneratingIDD: false,
      isCreateIssueModalVisible: false,
      isAddScanModalVisible: false,
      isDownloadingScan: false,
      isSendingEmailAgain: false,
      isSendingEmail: false,
      isSendEmailModalVisible: false,
      issues: {
        headers: [
          {
            key: "issueNumber",
            style: "width: 120px;",
            text: "NR SPRAWY"
          },
          {
            key: "itemName",
            style: "width: 180px; margin-left: 30px;",
            text: "PRZEDMIOT"
          },
          {
            key: "product",
            style: "width: 140px; padding-left: 20px;",
            text: "PRODUKT"
          },
          {
            key: "description",
            style: "width: 160px; margin-left: 50px;",
            text: "OPIS WŁASNY"
          }
        ],
        items: [
          {
            id: {
              key: "id",
              value: 1
            },
            issueNumber: {
              key: "issueNumber",
              value: 345678
            },
            itemName: {
              key: "itemName",
              value: "Wrocław, Kawalerzystów 33/7"
            },
            product: {
              key: "product",
              value: "DOM"
            },
            description: {
              key: "description",
              value: "mieszkanie na wynajem..."
            }
          },
          {
            id: {
              key: "id",
              value: 2
            },
            issueNumber: {
              key: "issueNumber",
              value: 567894
            },
            itemName: {
              key: "itemName",
              value: "Kędzierzyn-Koźle, Józefa Piłsud"
            },
            product: {
              key: "product",
              value: "ŻYCIE"
            },
            description: {
              key: "description",
              value: ""
            }
          },
          {
            id: {
              key: "id",
              value: 3
            },
            issueNumber: {
              key: "issueNumber",
              value: 555689
            },
            itemName: {
              key: "itemName",
              value: "DW 9087E"
            },
            product: {
              key: "product",
              value: "AUTO"
            },
            description: {
              key: "description",
              value: ""
            }
          },
          {
            id: {
              key: "id",
              value: 4
            },
            issueNumber: {
              key: "issueNumber",
              value: 122340
            },
            itemName: {
              key: "itemName",
              value: "Austria"
            },
            product: {
              key: "product",
              value: "PODRÓŻ"
            },
            description: {
              key: "description",
              value: ""
            }
          }
        ]
      }
    };
  },
  computed: {
    getTranslatedStatementType() {
      if (this.form.statement.type === "online") {
        return "Online-wyślij mail do klienta";
      } else if (this.form.statement.type === "print") {
        return "Druk z podpisem";
      }

      return "";
    },
    isFormDisabled() {
      return this.isGeneratingIDD;
    },
    isSendEmailButtonDisabled() {
      return this.v.$invalid;
    },
    isIssueLoaded() {
      return this.state.isIssueLoaded;
    },
    isIssueLoadedNotNull() {
      return this.state.isIssueLoaded !== null;
    },
    getTranslatedStatus() {
      switch (this.form.statement.status) {
        case "empty":
          return "Brak skanu";
        case "verifing":
          return "Wgrano skan";
        case "waitingForClientConfirmation":
          return "<span style='color: #993333;'>Oczekuje na potwierdzenie przez klienta</span>";
        case "confirmedByClient":
          return "Potwierdzone przez klienta";
        default:
          return "Brak skanu";
      }
    },
    getStatusColor() {
      switch (this.form.statement.status) {
        case "empty":
          return "text-danger";
        case "verifing":
          return "text-success";
        case "waitingForClientConfirmation":
          return "text-muted";
        case "confirmedByClient":
          return "text-success";
        default:
          return "text-danger";
      }
    }
  },
  watch: {
    v: {
      handler() {
        this.$nextTick(() => {
          for (let item in this.v.statement) {
            if (this.v.statement[item].hasOwnProperty("$reset")) {
              if (
                (this.v.statement[item].$model !== "" &&
                  this.v.statement[item].$model !== null) ||
                (this.v.statement[item].$dirty &&
                  !this.v.statement[item].$error &&
                  this.v.statement[item].$model === "" &&
                  this.v.statement[item].$model === null)
              ) {
                this.v.statement[item].$reset();
              }
            }
          }
          for (let item in this.v.issue) {
            if (this.v.issue[item].hasOwnProperty("$reset")) {
              if (
                (this.v.issue[item].$model !== "" &&
                  this.v.issue[item].$model !== null) ||
                (this.v.issue[item].$dirty &&
                  !this.v.issue[item].$error &&
                  this.v.issue[item].$model === "" &&
                  this.v.issue[item].$model === null)
              ) {
                this.v.issue[item].$reset();
              }
            }
          }
        });
      },
      deep: true
    }
  },
  methods: {
    isStatementFieldInvalid(field) {
      return this.v.statement[field].$dirty && this.v.statement[field].$invalid;
    },
    handleOpenCreateIssueModal() {
      this.isCreateIssueModalVisible = true;
    },
    handleCloseCreateIssueModal() {
      this.isCreateIssueModalVisible = false;
      this.handleCreateIssue();
    },
    handleOpenUploadScanModal() {
      this.isAddScanModalVisible = true;
    },
    handleOpenSendEmailModal() {
      this.isSendEmailModalVisible = true;
    },
    handleSendEmailAgain() {
      this.isSendingEmailAgain = true;
      setTimeout(() => {
        this.isSendingEmailAgain = false;
      }, 1000);
    },
    handleAddScan() {
      this.isAddScanModalVisible = false;
      this.form.statement.scanFile = "file";
      this.form.statement.status = "verifing";
    },
    handleSendEmail() {
      this.isSendingEmail = true;
      setTimeout(() => {
        this.isSendingEmail = false;
        this.isSendEmailModalVisible = false;
        this.state.statement.isMailSent = true;
      }, 1000);
    },
    handleDownloadScan() {
      this.isDownloadingScan = true;
      setTimeout(() => {
        this.isDownloadingScan = false;
      }, 1000);
    },
    handleCreateIssue() {
      this.state.activeTab = "statement";
      this.form.issue.issueId = 23232;
    },
    handleGenerateIDD() {
      this.isGeneratingIDD = true;
      setTimeout(() => {
        this.isGeneratingIDD = false;
        this.state.statement.isIddGenerated = true;
      }, 1000);
    },
    handleSetCurrentStep(step) {
      this.state.activeTab = step;
      scrollToElement(".top");
    },
    handleResetIDDStatement() {
      this.state.statement.isLoaded = false;
      this.form.statement.workWithClientType = "";
      this.form.statement.apk = "";
      this.form.statement.type = "";
      this.form.statement.meetingDate = "";
      this.form.statement.scanFile = null;
      this.form.statement.status = "empty";
      this.state.statement.isMailSent = false;
      this.state.statement.isIddGenerated = false;
    },
    handleFillIDDStatement() {
      this.state.statement.isLoaded = true;
      this.form.statement.workWithClientType =
        "Bezpośrednio - w oddziale/u klienta";
      this.form.statement.apk = "APK OK - Klient wybrał produkt z rekomendacji";
      this.form.statement.type = "print";
      this.form.statement.meetingDate = "01-01-2021";
      this.form.statement.status = "empty";
      this.state.statement.isIddGenerated = true;
    },
    handleAssignIssue() {
      this.form.chosenIssue = this.form.issueFromList;
    },
    handleConfirmByClient() {
      this.isClientEmailSending = false;
      this.form.statement.status = "confirmedByClient";
    },
    handleVerifyScan() {
      this.form.statement.status = "waitingForClientConfirmation";
    },
    handleChangeDate(event, field) {
      const { value } = event.target;
      this.form.statement[field] = value
        ? DateTime.fromFormat(value, CONSTANTS().dateYmdFormat).toFormat(
            CONSTANTS().dateYmdFormat
          )
        : "";
    }
  }
};
</script>
