<template>
  <fieldset>
    <div style="margin-top: 30px">
      <div class="row">
        <div class="col-lg-6">
          <div
            class="form-group"
            :class="isFieldInvalid('street') && 'invalid'"
          >
            <label for="street">Ulica</label>
            <input
              id="street"
              v-model="form.street"
              type="text"
              class="form-control"
              name="street"
            />
            <div v-if="isFieldInvalid('street')" class="invalid-feedback">
              Uzupełnij to pole
            </div>
          </div>
        </div>
      </div>
      <div class="d-flex">
        <div style="width: 100px; margin-right: 60px">
          <div
            class="form-group"
            :class="isFieldInvalid('houseNumber') && 'invalid'"
          >
            <label for="houseNumber">Numer domu</label>
            <input
              id="houseNumber"
              v-model="form.houseNumber"
              type="text"
              class="form-control"
              name="houseNumber"
            />
            <div v-if="isFieldInvalid('houseNumber')" class="invalid-feedback">
              Uzupełnij to pole
            </div>
          </div>
        </div>
        <div style="width: 100px">
          <div class="form-group">
            <label for="apartmentNumber">Numer lokalu</label>
            <input
              id="apartmentNumber"
              v-model="form.apartmentNumber"
              type="text"
              class="form-control"
              name="apartmentNumber"
            />
          </div>
        </div>
      </div>
      <div class="d-flex">
        <div style="width: 100px">
          <div
            class="form-group"
            :class="isFieldInvalid('postalCode') && 'invalid'"
          >
            <label for="postalCode">Kod pocztowy</label>
            <input
              id="postalCode"
              v-model="form.postalCode"
              v-mask="'XX-XXX'"
              type="text"
              class="form-control"
              name="postalCode"
            />
            <div v-if="isFieldInvalid('postalCode')" class="invalid-feedback">
              Uzupełnij to pole
            </div>
          </div>
        </div>
      </div>
      <div class="row">
        <div class="col-lg-6">
          <div
            class="form-group mb-0"
            :class="isFieldInvalid('city') && 'invalid'"
          >
            <label for="city">Miejscowość</label>
            <input
              id="city"
              v-model="form.city"
              type="text"
              class="form-control"
              name="city"
            />
            <div v-if="isFieldInvalid('city')" class="invalid-feedback">
              Uzupełnij to pole
            </div>
          </div>
        </div>
      </div>
    </div>
    <div
      class="d-flex align-items-center"
      style="margin-top: 50px; margin-bottom: 15px"
    >
      <div class="check-buttons">
        <div class="check">
          <input
            id="cessionEnabled"
            v-model="form.cessionEnabled"
            type="checkbox"
            name="cessionEnabled"
          />
          <label for="cessionEnabled">Cesja na bank</label>
        </div>
      </div>
      <div
        class="form-group mb-0"
        :class="isFieldInvalid('cessionBank') && 'invalid'"
        style="width: 260px; margin-left: 30px; margin-top: 2px"
      >
        <Select
          id="cessionBankName"
          v-model="form.cessionBank"
          :options="cessionBanks"
          hide-label
          name="cessionBankName"
        />
        <div v-if="isFieldInvalid('cessionBank')" class="invalid-feedback">
          Uzupełnij to pole
        </div>
      </div>
    </div>
  </fieldset>
</template>

<script>
import Select from "@/view/content/components/select/Select";
export default {
  name: "AddressForm",
  components: { Select },
  props: ["v", "form", "state", "chosenAddressId"],
  data() {
    return {
      cessionBanks: [
        { text: "Wybierz opcję", value: "" },
        { text: "ING", value: "ing" },
        { text: "BNP Paribas", value: "bnp_paribas" }
      ]
    };
  },

  watch: {
    form: {
      handler() {
        if (this.chosenAddressId === -1) {
          this.$nextTick(() => {
            this.$emit("updateSection", "collapse-add-new-address");
          });
        }
      },
      deep: true
    }
  },
  methods: {
    handleErase(field) {
      this.form[field] = "";
    },
    isFieldInvalid(field) {
      return this.v[field].$dirty && this.v[field].$invalid;
    }
  }
};
</script>
