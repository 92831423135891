<template>
  <div>
    <h3 class="text-secondary content-heading">Rejestracja polisy</h3>
    <div class="steps-wrapper custom-steps-1">
      <Nav
        :state="state"
        :form="form"
        :is-current-step="isCurrentStep"
        :handle-change-step="handleChangeStep"
      />
      <div class="step-content">
        <form novalidate @submit.stop.prevent="handleSubmit">
          <transition name="fade">
            <div v-show="isCurrentStep(1)" id="step1" class="step-wrapper">
              <Basic
                :form="form.basic"
                :state="state.basic"
                :v="$v.form.basic"
                :reset-form="resetForm"
              />
            </div>
          </transition>
          <transition name="fade">
            <div v-show="isCurrentStep(2)" id="step2" class="step-wrapper">
              <Policy
                :form="form.policy"
                :state="state.policy"
                :v="$v.form.policy"
                :policy-type="form.basic.type"
              />
            </div>
          </transition>
          <transition name="fade">
            <div v-show="isCurrentStep(3)" id="step3" class="step-wrapper">
              <Client
                :policy-type="form.basic.type"
                :v="$v.form.client"
                :form="form.client"
                :state="state.client"
              />
            </div>
          </transition>
          <transition name="fade">
            <div v-show="isCurrentStep(4)" id="step4" class="step-wrapper">
              <Rodo
                :v="$v.form.rodo"
                :client="form.client.insurer"
                :form="form.rodo"
                :state="state.rodo"
              />
            </div>
          </transition>
          <transition name="fade">
            <div v-show="isCurrentStep(5)" id="step5" class="step-wrapper">
              <InsuranceObject
                :policy-type="form.basic.type"
                :form="form.insuranceObject"
                :v="$v.form.insuranceObject"
                :state="state.insuranceObject"
              />
            </div>
          </transition>
          <transition name="fade">
            <div v-show="isCurrentStep(6)" id="step6" class="step-wrapper">
              <IDD
                :policy-type="form.idd"
                :form="form.idd"
                :v="$v.form.idd"
                :state="state.idd"
              />
              <Modal
                :opened="isFinishedModalVisible"
                variant="info"
                hide-close-icon
                no-header
                cancel-text="Pulpit"
                cancel-variant="btn-primary-light"
                save-text="Kolejna polisa"
                show-buttons
                @onClose="handleGoToDashboard"
                @onSave="handleNewPolicy"
              >
                <p
                  class="text-center text-secondary mb-0"
                  style="line-height: 30px"
                >
                  Zarejestrowaliśmy Twoją polisę nr 5675940332.<br />
                  Znajdziesz ją w swoim profilu.
                </p>
              </Modal>
            </div>
          </transition>
          <div class="step-actions">
            <div class="row align-items-center">
              <div class="col-4">
                <img
                  src="/media/components/steps/steps-previous-arrow.svg"
                  alt=""
                  class="cursor-pointer"
                  @click="handlePreviousStep"
                />
              </div>
              <div class="col-4 text-center">
                <button
                  v-show="!isLastStep() || state.idd.activeTab === 'issue'"
                  type="button"
                  class="btn btn-primary btn-lg btn-normal"
                  @click="handleNextStep"
                >
                  Dalej
                </button>
                <button
                  v-show="isLastStep() && state.idd.activeTab === 'statement'"
                  type="submit"
                  class="btn btn-primary btn-lg"
                  :disabled="
                    !state.idd.statement.isIddGenerated &&
                      !state.idd.statement.isLoaded &&
                      !state.idd.statement.isMailSent
                  "
                >
                  Zarejestruj polisę
                </button>
              </div>
            </div>
          </div>
        </form>
      </div>
    </div>
  </div>
</template>

<script>
import Basic from "./steps/basic/Basic";
import Policy from "./steps/policy/Policy";
import Client from "./steps/client/Client";
import Rodo from "./steps/rodo/Rodo";
import InsuranceObject from "./steps/insurance-object/InsuranceObject";
import IDD from "./steps/idd/IDD";

import Nav from "./Nav";

import { scrollToElement } from "@/core/helpers/ScrollToElement";
import {
  required,
  requiredIf,
  minLength,
  maxLength,
  email
} from "vuelidate/lib/validators";
import vinValidator from "@/core/utils/validators/vin";
import peselValidator from "@/core/utils/validators/pesel";
import nipValidator from "@/core/utils/validators/nip";

import {
  INITIAL_FORM_DATA,
  INITIAL_FORM_STATE
} from "@/core/helpers/PolicyFormState";
import Modal from "@/view/content/components/modal/Modal";
import {CONSTANTS} from "@/core/helpers/Constants";

export default {
  name: "RegisterPolicyView",
  components: {
    Modal,
    Nav,
    Basic,
    Policy,
    Client,
    Rodo,
    InsuranceObject,
    IDD
  },
  data() {
    return {
      currentStep: 1,
      lastStep: 6,
      isFinishedModalVisible: false,
      state: { ...INITIAL_FORM_STATE() },
      form: { ...INITIAL_FORM_DATA() }
    };
  },
  computed: {
    isVehicleTypeOther() {
      return this.form.insuranceObject.vehicle.type === "inne";
    },
    isIDDNextButtonVisible() {
      return this.state.idd.activeTab === "issue";
    },
    isClientInsuredChildrenRequired() {
      return this.state.client.insured.hasChildren;
    },
    constantsPolicyTypeAuto() {
      return CONSTANTS().policyTypeAuto;
    },
    constantsPolicyTypeProperty() {
      return CONSTANTS().policyTypeProperty;
    },
    constantsPolicyTypeLife() {
      return CONSTANTS().policyTypeLife;
    }
  },
  validations() {
    return {
      form: {
        basic: {
          type: {
            required
          },
          insurer: {
            required
          },
          productType: {
            required
          },
          policyNumber: {
            required
          }
        },
        policy: {
          policyIssueDate: {
            required
          },
          startDate: {
            required
          },
          endDate: {
            required
          },
          period: {
            required
          },
          installmentsNumber: {
            required: requiredIf(function() {
              return !this.isPolicyType(this.constantsPolicyTypeLife);
            })
          },
          installments: {
            $each: {
              price: {
                required
              },
              paymentDate: {
                required
              }
            }
          },
          paymentFrequency: {
            required: requiredIf(function() {
              return this.isPolicyType(this.constantsPolicyTypeLife);
            })
          },
          risksOnPolicy: {
            $each: {
              name: {},
              insuranceSum: {
                required: requiredIf(function(obj) {
                  return obj.name !== "";
                })
              }
            }
          },
          advisor: {
            required: requiredIf(function() {
              return this.isPolicyType(this.constantsPolicyTypeAuto);
            })
          }
        },
        insuranceObject: {
          vehicle: {
            type: {
              required: requiredIf(function() {
                return this.isPolicyType(this.constantsPolicyTypeAuto);
              })
            },
            vin: {
              required: requiredIf(function() {
                return this.isPolicyType(this.constantsPolicyTypeAuto) && !this.isVehicleTypeOther;
              }),
              isValid:
                this.form.insuranceObject.vehicle.vin !== "" && vinValidator
            },
            registrationNumber: {
              required: requiredIf(function() {
                return this.isPolicyType(this.constantsPolicyTypeAuto) && !this.isVehicleTypeOther;
              })
            },
            firstRegistrationDate: {
              required: requiredIf(function() {
                return this.isPolicyType(this.constantsPolicyTypeAuto) && !this.isVehicleTypeOther;
              })
            },
            make: {
              required: requiredIf(function() {
                return this.isPolicyType(this.constantsPolicyTypeAuto) && !this.isVehicleTypeOther;
              })
            },
            productionYear: {
              required: requiredIf(function() {
                return this.isPolicyType(this.constantsPolicyTypeAuto) && !this.isVehicleTypeOther;
              })
            },
            fuelType: {
              required: requiredIf(function() {
                return this.isPolicyType(this.constantsPolicyTypeAuto) && !this.isVehicleTypeOther;
              })
            },
            engineCapacity: {
              required: requiredIf(function() {
                return this.isPolicyType(this.constantsPolicyTypeAuto) && !this.isVehicleTypeOther;
              })
            },
            numberOfDoors: {
              required: requiredIf(function() {
                return this.isPolicyType(this.constantsPolicyTypeAuto) && !this.isVehicleTypeOther;
              })
            },
            bodyType: {},
            enginePowerKM: {},
            infoEkspertModel: {
              required: requiredIf(function() {
                return this.isPolicyType(this.constantsPolicyTypeAuto) && !this.isVehicleTypeOther;
              })
            },
            euroTaxModel: {
              required: requiredIf(function() {
                return this.isPolicyType(this.constantsPolicyTypeAuto) && !this.isVehicleTypeOther;
              })
            },
            otherIdentityNumber: {
              required: requiredIf(function() {
                return this.isPolicyType(this.constantsPolicyTypeAuto) && this.isVehicleTypeOther;
              })
            },
            otherType: {
              required: requiredIf(function() {
                return this.isPolicyType(this.constantsPolicyTypeAuto) && this.isVehicleTypeOther;
              })
            },
            otherMake: {
              required: requiredIf(function() {
                return this.isPolicyType(this.constantsPolicyTypeAuto) && this.isVehicleTypeOther;
              })
            },
            otherModel: {
              required: requiredIf(function() {
                return this.isPolicyType(this.constantsPolicyTypeAuto) && this.isVehicleTypeOther;
              })
            }
          },
          house: {
            cessionEnabled: {},
            cessionBank: {
              required: requiredIf(function() {
                return this.form.insuranceObject.house.cessionEnabled;
              })
            },
            street: {
              required: requiredIf(function() {
                return (
                  this.isPolicyType(this.constantsPolicyTypeProperty) &&
                  this.form.basic.productType !== "inne"
                );
              })
            },
            houseNumber: {
              required: requiredIf(function() {
                return (
                  this.isPolicyType(this.constantsPolicyTypeProperty) &&
                  this.form.basic.productType !== "inne"
                );
              })
            },
            apartmentNumber: {},
            postalCode: {
              required: requiredIf(function() {
                return (
                  this.isPolicyType(this.constantsPolicyTypeProperty) &&
                  this.form.basic.productType !== "inne"
                );
              })
            },
            city: {
              required: requiredIf(function() {
                return (
                  this.isPolicyType(this.constantsPolicyTypeProperty) &&
                  this.form.basic.productType !== "inne"
                );
              })
            }
          }
        },
        client: {
          insurer: {
            roles: {
              required: requiredIf(() => {
                return this.isClientInsurerFieldRequired("roles");
              })
            },
            legalForm: {
              required: requiredIf(() => {
                return this.isClientInsurerFieldRequired("legalForm");
              })
            },
            regon: {
              required: requiredIf(() => {
                return this.isClientInsurerFieldRequired("regon");
              })
            },
            companyName: {
              required: requiredIf(function() {
                return this.isClientInsurerFieldRequired("companyName");
              })
            },
            nip: {
              required: requiredIf(function() {
                return this.isClientInsurerFieldRequired("nip");
              }),
              isValid: this.form.client.insurer.nip !== "" && nipValidator
            },
            pesel: {
              required: requiredIf(function() {
                return this.isClientInsurerFieldRequired("pesel");
              }),
              isValid: this.form.client.insurer.pesel !== "" && peselValidator
            },
            firstName: {
              required: requiredIf(function() {
                return this.isClientInsurerFieldRequired("firstName");
              })
            },
            lastName: {
              required: requiredIf(function() {
                return this.isClientInsurerFieldRequired("lastName");
              })
            },
            birthDate: {
              required: requiredIf(function() {
                return this.isClientInsurerFieldRequired("birthDate");
              })
            },
            gender: {
              required: requiredIf(function() {
                return this.isClientInsurerFieldRequired("gender");
              })
            },
            phoneNumber: {
              minLength:
                this.form.client.insurer.phoneNumber !== "" && minLength(11),
              maxLength:
                this.form.client.insurer.phoneNumber !== "" && maxLength(11)
            },
            email: {
              required: requiredIf(function() {
                return this.isClientInsurerFieldRequired("email");
              }),
              isValid: this.form.client.insurer.email !== "" && email
            }
          },
          insured: {
            children: {
              $each: {
                firstName: {
                  required: requiredIf(function() {
                    return this.isClientInsuredChildrenRequired;
                  })
                },
                lastName: {
                  required: requiredIf(function() {
                    return this.isClientInsuredChildrenRequired;
                  })
                },
                birthDate: {
                  required: requiredIf(function() {
                    return this.isClientInsuredChildrenRequired;
                  })
                }
              }
            },
            roles: {
              required: requiredIf(function() {
                return this.isClientInsuredFieldRequired("roles");
              })
            },
            legalForm: {
              required: requiredIf(function() {
                return this.isClientInsuredFieldRequired("legalForm");
              })
            },
            regon: {
              required: requiredIf(function() {
                return this.isClientInsuredFieldRequired("regon");
              })
            },
            companyName: {
              required: requiredIf(function() {
                return this.isClientInsuredFieldRequired("companyName");
              })
            },
            nip: {
              required: requiredIf(function() {
                return this.isClientInsuredFieldRequired("nip");
              }),
              isValid: this.form.client.insured.nip !== "" && nipValidator
            },
            pesel: {
              required: requiredIf(function() {
                return this.isClientInsuredFieldRequired("pesel");
              }),
              isValid: this.form.client.insured.pesel !== "" && peselValidator
            },
            firstName: {
              required: requiredIf(function() {
                return this.isClientInsuredFieldRequired("firstName");
              })
            },
            lastName: {
              required: requiredIf(function() {
                return this.isClientInsuredFieldRequired("lastName");
              })
            },
            birthDate: {
              required: requiredIf(function() {
                return this.isClientInsuredFieldRequired("birthDate");
              })
            },
            gender: {
              required: requiredIf(function() {
                return this.isClientInsuredFieldRequired("gender");
              })
            },
            phoneNumber: {
              required: requiredIf(function() {
                return this.isClientInsuredFieldRequired("phoneNumber");
              }),
              minLength:
                this.form.client.insured.phoneNumber !== "" && minLength(11),
              maxLength:
                this.form.client.insured.phoneNumber !== "" && maxLength(11)
            },
            email: {
              required: requiredIf(function() {
                return this.isClientInsuredFieldRequired("email");
              }),
              isValid: this.form.client.insured.email !== "" && email
            }
          },
          user: {
            roles: {
              required: requiredIf(function() {
                return this.isClientUserFieldRequired("roles");
              })
            },
            legalForm: {
              required: requiredIf(function() {
                return this.isClientUserFieldRequired("legalForm");
              })
            },
            regon: {
              required: requiredIf(function() {
                return this.isClientUserFieldRequired("regon");
              })
            },
            companyName: {
              required: requiredIf(function() {
                return this.isClientUserFieldRequired("companyName");
              })
            },
            nip: {
              required: requiredIf(function() {
                return this.isClientUserFieldRequired("nip");
              }),
              isValid: this.form.client.user.nip !== "" && nipValidator
            },
            pesel: {
              required: requiredIf(function() {
                return this.isClientUserFieldRequired("pesel");
              }),
              isValid: this.form.client.user.pesel !== "" && peselValidator
            },
            firstName: {
              required: requiredIf(function() {
                return this.isClientUserFieldRequired("firstName");
              })
            },
            lastName: {
              required: requiredIf(function() {
                return this.isClientUserFieldRequired("lastName");
              })
            },
            birthDate: {
              required: requiredIf(function() {
                return this.isClientUserFieldRequired("birthDate");
              })
            },
            gender: {
              required: requiredIf(function() {
                return this.isClientUserFieldRequired("gender");
              })
            },
            phoneNumber: {
              required: requiredIf(function() {
                return this.isClientUserFieldRequired("phoneNumber");
              }),
              minLength:
                this.form.client.user.phoneNumber !== "" && minLength(11),
              maxLength:
                this.form.client.user.phoneNumber !== "" && maxLength(11)
            },
            email: {
              required: requiredIf(function() {
                return this.isClientUserFieldRequired("email");
              }),
              isValid: this.form.client.user.email !== "" && email
            }
          },
          owner: {
            roles: {
              required: requiredIf(function() {
                return this.isClientOwnerFieldRequired("roles");
              })
            },
            legalForm: {
              required: requiredIf(function() {
                return this.isClientOwnerFieldRequired("legalForm");
              })
            },
            regon: {
              required: requiredIf(function() {
                return this.isClientOwnerFieldRequired("regon");
              })
            },
            companyName: {
              required: requiredIf(function() {
                return this.isClientOwnerFieldRequired("companyName");
              })
            },
            nip: {
              required: requiredIf(function() {
                return this.isClientOwnerFieldRequired("nip");
              }),
              isValid: this.form.client.owner.nip !== "" && nipValidator
            },
            pesel: {
              required: requiredIf(function() {
                return this.isClientOwnerFieldRequired("pesel");
              }),
              isValid: this.form.client.owner.pesel !== "" && peselValidator
            },
            firstName: {
              required: requiredIf(function() {
                return this.isClientOwnerFieldRequired("firstName");
              })
            },
            lastName: {
              required: requiredIf(function() {
                return this.isClientOwnerFieldRequired("lastName");
              })
            },
            birthDate: {
              required: requiredIf(function() {
                return this.isClientOwnerFieldRequired("birthDate");
              })
            },
            gender: {
              required: requiredIf(function() {
                return this.isClientOwnerFieldRequired("gender");
              })
            },
            phoneNumber: {
              required: requiredIf(function() {
                return this.isClientOwnerFieldRequired("phoneNumber");
              }),
              minLength:
                this.form.client.owner.phoneNumber !== "" && minLength(11),
              maxLength:
                this.form.client.owner.phoneNumber !== "" && maxLength(11)
            },
            email: {
              required: requiredIf(function() {
                return this.isClientOwnerFieldRequired("email");
              }),
              isValid: this.form.client.owner.email !== "" && email
            }
          },
          leasingOwner: {
            roles: {
              required: requiredIf(function() {
                return this.isClientLeasingOwnerFieldRequired("roles");
              })
            },
            regon: {
              required: requiredIf(function() {
                return this.isClientLeasingOwnerFieldRequired("regon");
              })
            },
            companyName: {
              required: requiredIf(function() {
                return this.isClientLeasingOwnerFieldRequired("companyName");
              })
            },
            nip: {
              required: requiredIf(function() {
                return this.isClientLeasingOwnerFieldRequired("nip");
              }),
              isValid: this.form.client.leasingOwner.nip !== "" && nipValidator
            },
            city: {
              required: requiredIf(function() {
                return this.isClientLeasingOwnerFieldRequired("city");
              })
            }
          },
          coOwners: {
            coOwnersCount: {
              required: requiredIf(function() {
                return (
                  this.isPolicyType(this.constantsPolicyTypeAuto) &&
                  this.state.client.isLeasing === false
                );
              })
            },
            coOwnersArray: {
              $each: {
                legalForm: {
                  required: requiredIf(function() {
                    return (
                      this.isPolicyType(this.constantsPolicyTypeAuto) &&
                      this.state.client.isLeasing === false
                    );
                  })
                },
                regon: {
                  required: requiredIf(
                    obj =>
                      obj.legalForm === "company" &&
                      this.isPolicyType(this.constantsPolicyTypeAuto) &&
                      this.state.client.isLeasing === false
                  )
                },
                companyName: {
                  required: requiredIf(
                    obj =>
                      obj.legalForm === "company" &&
                      this.isPolicyType(this.constantsPolicyTypeAuto) &&
                      this.state.client.isLeasing === false
                  )
                },
                nip: {
                  required: requiredIf(
                    obj =>
                      obj.legalForm === "company" &&
                      this.isPolicyType(this.constantsPolicyTypeAuto) &&
                      this.state.client.isLeasing === false
                  ),
                  isValid: obj => obj.nip !== "" && nipValidator
                },
                pesel: {
                  required: requiredIf(
                    obj =>
                      !obj.withoutPesel &&
                      obj.legalForm === "private" &&
                      this.isPolicyType(this.constantsPolicyTypeAuto) &&
                      this.state.client.isLeasing === false
                  ),
                  isValid: obj => obj.pesel !== "" && peselValidator
                },
                firstName: {
                  required: requiredIf(
                    obj =>
                      obj.legalForm === "private" &&
                      this.isPolicyType(this.constantsPolicyTypeAuto) &&
                      this.state.client.isLeasing === false
                  )
                },
                lastName: {
                  required: requiredIf(
                    obj =>
                      obj.legalForm === "private" &&
                      this.isPolicyType(this.constantsPolicyTypeAuto) &&
                      this.state.client.isLeasing === false
                  )
                },
                birthDate: {
                  required: requiredIf(
                    obj =>
                      obj.legalForm === "private" &&
                      this.isPolicyType(this.constantsPolicyTypeAuto) &&
                      this.state.client.isLeasing === false
                  )
                },
                gender: {
                  required: requiredIf(
                    obj =>
                      obj.legalForm === "private" &&
                      this.isPolicyType(this.constantsPolicyTypeAuto) &&
                      this.state.client.isLeasing === false
                  )
                },
                phoneNumber: {
                  required: requiredIf(function() {
                    return (
                      this.isPolicyType(this.constantsPolicyTypeAuto) &&
                      this.state.client.isLeasing === false
                    );
                  }),
                  minLength: obj => obj.phoneNumber !== "" && minLength(11),
                  maxLength: obj => obj.phoneNumber !== "" && maxLength(11)
                },
                email: {
                  required: requiredIf(
                    obj =>
                      !obj.withoutEmail &&
                      this.isPolicyType(this.constantsPolicyTypeAuto) &&
                      this.state.client.isLeasing === false
                  ),
                  isValid: obj => obj.email !== "" && email
                }
              }
            }
          }
        },
        rodo: {},
        idd: {
          issue: {
            issueId: {
              required: requiredIf(function() {
                return !this.state.idd.statement.isLoaded;
              })
            }
          },
          statement: {
            workWithClientType: {
              required: requiredIf(function() {
                return !this.state.idd.statement.isLoaded;
              })
            },
            apk: {
              required: requiredIf(function() {
                return !this.state.idd.statement.isLoaded;
              })
            },
            type: {
              required: requiredIf(function() {
                return !this.state.idd.statement.isLoaded;
              })
            },
            meetingDate: {
              required: requiredIf(function() {
                return !this.state.idd.statement.isLoaded;
              })
            },
            status: {},
            scanFile: {}
          }
        }
      }
    };
  },
  watch: {
    currentStep(newVal) {
      if (newVal === 3) {
        this.state.client.currentStep = "insurer";
      }
    }
  },
  methods: {
    handleGoToDashboard() {
      this.$router.push("/dashboard");
      this.isFinishedModalVisible = false;
    },
    handleNewPolicy() {
      this.$nextTick(() => {
        this.resetForm();
        this.isFinishedModalVisible = false;
      });
    },
    isCurrentStep(step) {
      return this.currentStep === step;
    },
    isLastStep() {
      return this.currentStep === this.lastStep;
    },
    getNextStep() {
      let nextStep = this.currentStep;
      nextStep += 1;
      return nextStep;
    },
    getPreviousStep() {
      let previousStep = this.currentStep;
      if (this.currentStep > 1) {
        previousStep -= 1;
      }
      return previousStep;
    },
    getStepNameByNumber(stepNumber) {
      switch (stepNumber) {
        case 1:
          return "basic";
        case 2:
          return "policy";
        case 3:
          return "client";
        case 4:
          return "rodo";
        case 5:
          return "insuranceObject";
        case 6:
          return "idd";
        default:
          return "";
      }
    },
    getClientStepNameByNumber(stepNumber) {
      switch (stepNumber) {
        case 1:
          return "insurer";
        case 2:
          if (this.form.basic.type === this.constantsPolicyTypeAuto) {
            if (this.state.client.isLeasing) {
              if (this.form.client.insurer.roles.includes("user")) {
                return "leasingOwner";
              } else {
                return "user";
              }
            } else {
              if (this.form.client.insurer.roles.includes("owner")) {
                return "coOwners";
              } else {
                return "owner";
              }
            }
          } else {
            return "insured";
          }
        case 3:
          if (this.state.client.isLeasing) {
            return "leasingOwner";
          } else {
            return "coOwners";
          }
      }
    },
    getClientStepNumberByStepName(stepName) {
      switch (stepName) {
        case "insurer":
          return 1;
        case "insured":
        case "owner":
          return 2;
        case "leasingOwner":
        case "coOwners":
          return 3;
      }
    },
    handleNextClientStep() {
      const stepName = this.getStepNameByNumber(this.currentStep);
      const clientStep = this.state.client.currentStep;
      let clientStepNumber = this.getClientStepNumberByStepName(clientStep);
      this.validateFormSection(stepName, clientStep);
      if (this.state[stepName][clientStep].formState) {
        clientStepNumber += 1;
        this.state.client.currentStep = this.getClientStepNameByNumber(
          clientStepNumber
        );
        scrollToElement(".top");
      }
    },
    getIDDStepNumberByStepName(stepName) {
      switch (stepName) {
        case "issue":
          return 1;
        case "statement":
          return 2;
      }
    },
    getIDDStepNameByNumber(stepNumber) {
      switch (stepNumber) {
        case 1:
          return "issue";
        case 2:
          return "statement";
      }
    },
    handleNextIDDStep() {
      const stepName = this.getStepNameByNumber(this.currentStep);
      const iddStep = this.state.idd.activeTab;
      let iddStepNumber = this.getIDDStepNumberByStepName(iddStep);
      this.validateFormSection(stepName, iddStep);
      if (this.state[stepName][iddStep].formState) {
        iddStepNumber += 1;
        this.state.idd.activeTab = this.getIDDStepNameByNumber(iddStepNumber);
        scrollToElement(".top");
      }
    },
    handleNextStep() {
      if (this.currentStep <= this.lastStep) {
        let clientStep = this.state.client.currentStep;
        let iddStep = this.state.idd.activeTab;
        if (
          (this.form.basic.type === this.constantsPolicyTypeAuto &&
            this.getNextStep() === 4 &&
            this.getClientStepNumberByStepName(clientStep) < 3) ||
          (this.form.basic.type !== this.constantsPolicyTypeAuto &&
            this.getNextStep() === 4 &&
            this.getClientStepNumberByStepName(clientStep) < 2 &&
            !this.form.client.insurer.roles.includes("insured"))
        ) {
          this.handleNextClientStep();
        } else if (
          this.getNextStep() === 7 &&
          this.getIDDStepNumberByStepName(iddStep) < 2
        ) {
          this.handleNextIDDStep();
        } else {
          const stepName = this.getStepNameByNumber(this.currentStep);
          this.validateFormSection(stepName);
          if (this.state[stepName].formState) {
            if (
              (this.form.basic.type === this.constantsPolicyTypeLife ||
                this.form.basic.productType === "inne") &&
              this.getNextStep() === 5
            ) {
              this.currentStep += 2;
            } else {
              this.currentStep += 1;
            }
          }
          if (this.currentStep === 5) {
            if (this.form.basic.type === this.constantsPolicyTypeAuto) {
              this.$root.$emit("updateSection", "collapse-add-new-vehicle");
            } else if (this.form.basic.type === this.constantsPolicyTypeProperty) {
              this.$root.$emit("updateSection", "collapse-add-new-address");
            }
          }
        }
      }
    },
    handlePreviousStep() {
      if (this.currentStep > 1) {
        this.currentStep -= 1;
      }
    },
    handleChangeStep(step) {
      if (step > this.getPreviousStep()) {
        for (let i = this.currentStep; i < step; i++) {
          const stepName = this.getStepNameByNumber(i);
          this.validateFormSection(stepName);
          if (!this.state[stepName].formState) {
            return false;
          }
          this.currentStep += 1;
        }
      } else {
        this.currentStep = step;
      }
    },
    isPolicyType(type) {
      return this.form.basic.type === type;
    },
    isClientPrivateRequiredFields(role) {
      return this.form.client[role].legalForm === "private";
    },
    isClientCompanyRequiredFields(role) {
      return (
        this.form.client[role].legalForm === "company" ||
        this.form.client[role].legalForm === "onePersonCompany"
      );
    },
    isClientLeasingOwnerFieldRequired(field) {
      switch (field) {
        case "companyName":
        case "nip":
        case "regon":
        case "city":
          return this.state.client.isLeasing === true;
      }
    },
    isClientInsurerFieldRequired(field) {
      switch (field) {
        case "role":
        case "legalForm":
          return true;
        case "companyName":
        case "nip":
        case "regon":
          return this.isClientCompanyRequiredFields("insurer");
        case "firstName":
        case "lastName":
          return (
            this.isClientPrivateRequiredFields("insurer") &&
            this.form.client.insurer.withoutPesel === true
          );
        case "birthDate":
        case "gender":
          return this.isClientPrivateRequiredFields("insurer");
        case "pesel":
          return (
            this.isClientPrivateRequiredFields("insurer") &&
            this.form.client.insurer.withoutPesel === false
          );
        case "email":
          return this.form.client.insurer.withoutEmail === false;
      }
    },
    isClientInsuredFieldRequired(field) {
      switch (field) {
        case "legalForm":
        case "role":
        case "phoneNumber":
          return (
            this.form.basic.type !== this.constantsPolicyTypeAuto &&
            this.state.client.insured.hasChildren === false &&
            !this.form.client.insurer.roles.includes("insured")
          );
        case "companyName":
        case "nip":
        case "regon":
          return (
            this.isClientCompanyRequiredFields("insured") &&
            this.form.basic.type !== this.constantsPolicyTypeAuto &&
            this.state.client.insured.hasChildren === false &&
            !this.form.client.insurer.roles.includes("insured")
          );
        case "firstName":
        case "lastName":
          return (
            this.isClientPrivateRequiredFields("insured") &&
            this.form.client.insured.withoutPesel === true &&
            !this.form.client.insurer.roles.includes("insured")
          );
        case "birthDate":
        case "gender":
          return (
            this.isClientPrivateRequiredFields("insured") &&
            this.form.basic.type !== this.constantsPolicyTypeAuto &&
            this.state.client.insured.hasChildren === false &&
            !this.form.client.insurer.roles.includes("insured")
          );
        case "pesel":
          return (
            this.isClientPrivateRequiredFields("insured") &&
            this.form.client.insured.withoutPesel === false &&
            this.form.basic.type !== this.constantsPolicyTypeAuto &&
            this.state.client.insured.hasChildren === false &&
            !this.form.client.insurer.roles.includes("insured")
          );
        case "email":
          return (
            this.form.client.insured.withoutEmail === false &&
            this.form.basic.type !== this.constantsPolicyTypeAuto &&
            this.state.client.insured.hasChildren === false &&
            !this.form.client.insurer.roles.includes("insured")
          );
      }
    },
    isClientUserFieldRequired(field) {
      switch (field) {
        case "phoneNumber":
        case "legalForm":
        case "role":
          return (
            this.state.client.isLeasing === true &&
            !this.form.client.insurer.roles.includes("user")
          );
        case "companyName":
        case "nip":
        case "regon":
          return (
            this.isClientCompanyRequiredFields("user") &&
            this.state.client.isLeasing === true &&
            !this.form.client.insurer.roles.includes("user")
          );
        case "firstName":
        case "lastName":
          return (
            this.isClientPrivateRequiredFields("user") &&
            this.form.client.user.withoutPesel === true &&
            !this.form.client.insurer.roles.includes("user")
          );
        case "birthDate":
        case "gender":
          return (
            this.isClientPrivateRequiredFields("user") &&
            this.state.client.isLeasing === true &&
            !this.form.client.insurer.roles.includes("user")
          );
        case "pesel":
          return (
            this.isClientPrivateRequiredFields("user") &&
            this.form.client.user.withoutPesel === false &&
            this.state.client.isLeasing === true &&
            !this.form.client.insurer.roles.includes("user")
          );
        case "email":
          return (
            this.form.client.user.withoutEmail === false &&
            this.state.client.isLeasing === true &&
            !this.form.client.insurer.roles.includes("user")
          );
      }
    },
    isClientOwnerFieldRequired(field) {
      switch (field) {
        case "phoneNumber":
        case "legalForm":
        case "role":
          return (
            this.isPolicyType(this.constantsPolicyTypeAuto) &&
            this.state.client.isLeasing === false &&
            !this.form.client.insurer.roles.includes("owner")
          );
        case "companyName":
        case "nip":
        case "regon":
          return (
            this.isClientCompanyRequiredFields("owner") &&
            this.isPolicyType(this.constantsPolicyTypeAuto) &&
            this.state.client.isLeasing === false &&
            !this.form.client.insurer.roles.includes("owner")
          );
        case "firstName":
        case "lastName":
          return (
            this.isClientPrivateRequiredFields("owner") &&
            this.form.client.owner.withoutPesel === true &&
            !this.form.client.insurer.roles.includes("owner")
          );
        case "birthDate":
        case "gender":
          return (
            this.isClientPrivateRequiredFields("owner") &&
            this.isPolicyType(this.constantsPolicyTypeAuto) &&
            this.state.client.isLeasing === false
          );
        case "pesel":
          return (
            this.isClientPrivateRequiredFields("owner") &&
            this.form.client.owner.withoutPesel === false &&
            this.isPolicyType(this.constantsPolicyTypeAuto) &&
            this.state.client.isLeasing === false &&
            !this.form.client.insurer.roles.includes("owner")
          );
        case "email":
          return (
            this.form.client.user.withoutEmail === false &&
            this.isPolicyType(this.constantsPolicyTypeAuto) &&
            this.state.client.isLeasing === false &&
            !this.form.client.insurer.roles.includes("owner")
          );
      }
    },
    resetForm() {
      this.state = INITIAL_FORM_STATE();
      this.form.basic.insurer = INITIAL_FORM_DATA().basic.insurer;
      this.form.basic.productType = INITIAL_FORM_DATA().basic.productType;
      this.form.basic.policyNumber = INITIAL_FORM_DATA().basic.policyNumber;

      this.form.policy = { ...INITIAL_FORM_DATA().policy };
      this.form.client = { ...INITIAL_FORM_DATA().client };
      this.form.client.insurer = { ...INITIAL_FORM_DATA().client.insurer };
      this.form.client.insured = { ...INITIAL_FORM_DATA().client.insured };
      this.form.client.user = { ...INITIAL_FORM_DATA().client.user };
      this.form.client.owner = { ...INITIAL_FORM_DATA().client.owner };
      this.form.client.coOwners = { ...INITIAL_FORM_DATA().client.coOwners };

      this.form.rodo = { ...INITIAL_FORM_DATA().rodo };
      this.form.idd = { ...INITIAL_FORM_DATA().idd };
      this.form.insuranceObject = { ...INITIAL_FORM_DATA().insuranceObject };
      this.form.insuranceObject.house = {
        ...INITIAL_FORM_DATA().insuranceObject.house
      };
      this.form.insuranceObject.vehicle = {
        ...INITIAL_FORM_DATA().insuranceObject.vehicle
      };

      this.currentStep = 1;

      this.$nextTick(() => {
        this.$v.$reset();
      });
    },
    validateFormSection(section, subsection = null) {
      if (subsection === null) {
        this.$v.form[section].$touch();
        this.state[section].formState = !this.$v.form[section].$invalid;
      } else {
        this.$v.form[section][subsection].$touch();
        this.state[section][subsection].formState = !this.$v.form[section][
          subsection
        ].$invalid;
      }
    },
    validateForm() {
      this.$v.$touch();
      this.state.formState = !this.$v.$invalid;
    },
    handleSubmit() {
      this.validateForm();
      if (this.state.formState) {
        this.isFinishedModalVisible = true;
      }
    }
  }
};
</script>
