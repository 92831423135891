<template>
  <div>
    <div class="dev-check-buttons">
      <button
        class="btn btn-primary-light"
        type="button"
        @click="state.areVehiclesFound = true"
      >
        Dev: Znaleziono pojazdy
      </button>
      <button
        class="btn btn-primary-light"
        type="button"
        @click="state.areVehiclesFound = false"
      >
        Dev: Nie znaleziono pojazdów
      </button>
    </div>
    <p class="text-secondary" style="margin-bottom: 30px">
      Wybierz pojazd z listy lub uzupełnij dane
    </p>
    <template v-if="state.areVehiclesFound">
      <div class="bordered-block" style="margin-bottom: 25px">
        <div class="radio-buttons">
          <template v-for="(vehicle, index) in vehicles">
            <div :key="index" class="radio">
              <input
                :id="'chosen-vehicle' + index"
                :value="vehicle"
                type="radio"
                name="chosen-vehicle"
                :checked="chosenVehicleId === vehicle.id"
                @change="handleChangeVehicle(vehicle)"
              />
              <label :for="'chosen-vehicle' + index">
                <div class="dot-list">
                  <div class="dot-list-item">
                    {{ vehicle.registrationNumber }}
                  </div>
                  <div class="dot-list-item">
                    {{ vehicle.modelName }}
                  </div>
                  <div class="dot-list-item">VIN {{ vehicle.vin }}</div>
                </div>
              </label>
            </div>
          </template>
        </div>
      </div>
      <Collapse
        id="collapse-add-new-vehicle"
        :collapsed="state.isAddNewVisible"
        @onCollapse="handleCollapseAddNewVehicle"
      >
        <template v-slot:heading>Dodaj inny pojazd</template>
        <template v-slot:content>
          <VehicleForm
            :v="v"
            :state="state"
            :form="form"
            :chosen-vehicle-id="chosenVehicleId"
          />
        </template>
      </Collapse>
    </template>
    <template v-else>
      <div class="bordered-block empty">
        <p class="font-italic text-secondary m-0">
          Brak pojazdów dla tego Klienta. Uzupełnij pola poniżej.
        </p>
      </div>
      <VehicleForm
        :v="v"
        :state="state"
        :form="form"
        :chosen-vehicle-id="chosenVehicleId"
      />
    </template>
  </div>
</template>

<script>
import Collapse from "@/view/content/components/collapse/Collapse";
import VehicleForm from "@/view/content/register-policy/steps/insurance-object/vehicle/VehicleForm";
export default {
  name: "Vehicle",
  components: { VehicleForm, Collapse },
  props: ["v", "form", "state"],
  data() {
    return {
      isVehicleChecking: false,
      chosenVehicleId: -1,
      vehicles: [
        {
          id: 1,
          type: "osobowe",
          vin: "JN8AS5MV2CW384323",
          registrationNumber: "WE 4567Q",
          firstRegistrationDate: "2011-01-01",
          make: 7,
          productionYear: "2011",
          fuelType: "E",
          engineCapacity: 2498,
          numberOfDoors: 4,
          enginePowerKM: 75,
          bodyType: "sedan",
          infoEkspertModel: 1,
          euroTaxModel: 1,
          modelName: "Volkswagen GOLF 1.2 TDI Comfort",
          otherIdentityNumber: "",
          otherType: "",
          otherMake: "",
          otherModel: ""
        },
        {
          id: 2,
          type: "osobowe",
          vin: "KL7CJTSB9FB065112",
          registrationNumber: "OP 7494C",
          firstRegistrationDate: "2011-01-01",
          make: 7,
          productionYear: "2011",
          fuelType: "E",
          engineCapacity: 2498,
          numberOfDoors: 4,
          enginePowerKM: 75,
          bodyType: "sedan",
          infoEkspertModel: 1,
          euroTaxModel: 1,
          modelName: "Opel Astra 1.8",
          otherIdentityNumber: "",
          otherType: "",
          otherMake: "",
          otherModel: ""
        }
      ]
    };
  },
  methods: {
    setIsVehicleChecking(value) {
      this.isVehicleChecking = value;
    },
    handleChangeVehicle(vehicle) {
      this.chosenVehicleId = vehicle.id;
      this.form.type = vehicle.type;
      this.form.vin = vehicle.vin;
      this.form.registrationNumber = vehicle.registrationNumber;
      this.form.firstRegistrationDate = vehicle.firstRegistrationDate;
      this.form.make = vehicle.make;
      this.form.productionYear = vehicle.productionYear;
      this.form.fuelType = vehicle.fuelType;
      this.form.engineCapacity = vehicle.engineCapacity;
      this.form.numberOfDoors = vehicle.numberOfDoors;
      this.form.enginePowerKM = vehicle.enginePowerKM;
      this.form.bodyType = vehicle.bodyType;
      this.form.infoEkspertModel = vehicle.infoEkspertModel;
      this.form.euroTaxModel = vehicle.euroTaxModel;
      this.form.otherIdentityNumber = vehicle.otherIdentityNumber;
      this.form.otherType = vehicle.otherType;
      this.form.otherMake = vehicle.otherMake;
      this.form.otherModel = vehicle.otherModel;

      this.state.isAddNewVisible = false;
      this.state.isLoaded = null;
    },
    handleCollapseAddNewVehicle() {
      this.state.isAddNewVisible = !this.state.isAddNewVisible;
      if (this.state.isAddNewVisible) {
        this.chosenVehicleId = -1;
        this.form.type = "";
        this.form.vin = "";
        this.form.registrationNumber = "";
        this.form.firstRegistrationDate = "";
        this.form.make = "";
        this.form.productionYear = "";
        this.form.fuelType = "";
        this.form.engineCapacity = "";
        this.form.numberOfDoors = "";
        this.form.enginePowerKM = "";
        this.form.bodyType = "";
        this.form.infoEkspertModel = "";
        this.form.euroTaxModel = "";
        this.form.otherIdentityNumber = "";
        this.form.otherType = "";
        this.form.otherMake = "";
        this.form.otherModel = "";

        this.state.isLoaded = null;

        this.v.$reset();
      }
    }
  }
};
</script>
