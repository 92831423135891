<template>
  <div class="bordered-block">
    <p class="text-secondary" :class="titleStyle">
      {{ title }}
    </p>
    <table class="table table-custom-1 check-table" :class="variant">
      <thead v-if="!noHeaders">
        <tr>
          <th></th>
          <template v-for="(header, index) in items.headers">
            <th :key="index" :style="header.style && header.style">
              {{ header.text }}
            </th>
          </template>
        </tr>
      </thead>
      <tbody>
        <tr v-for="(item, index) in items.items" :key="index">
          <td style="width: 15px; padding-left: 0">
            <div class="radio-buttons">
              <div :key="index" class="radio">
                <input
                  :id="field + '-' + item.id.value"
                  v-model="v[field].$model"
                  :value="item.id.value"
                  type="radio"
                  :name="field"
                />
                <label>&nbsp;</label>
              </div>
            </div>
          </td>
          <template v-for="(header, index2) in items.headers">
            <td v-if="item[header.key]" :key="index2" :style="header.style">
              <label
                :for="field + '-' + item.id.value"
                class="cursor-pointer"
                :class="isSelected(item.id.value) ? 'text-secondary' : ''"
              >
                <span
                  :style="
                    item[header.key].hasOwnProperty('style')
                      ? item[header.key].style
                      : null
                  "
                  >{{ item[header.key].value }}</span
                >
              </label>
            </td>
          </template>
        </tr>
      </tbody>
    </table>
  </div>
</template>

<script>
export default {
  name: "CheckTable",
  props: {
    field: String,
    items: Object,
    title: String,
    titleStyle: String,
    v: Object,
    noHeaders: Boolean,
    variant: {
      type: String,
      default: "normal"
    }
  },
  data() {
    return {};
  },
  computed: {},
  methods: {
    isSelected(id) {
      return this.v[this.field].$model === id;
    }
  }
};
</script>

<style scoped></style>
