export const INITIAL_FORM_DATA = () => {
  return {
    basic: {
      type: "",
      insurer: null,
      productType: null,
      policyNumber: ""
    },
    policy: {
      policyIssueDate: "",
      startDate: "",
      endDate: "",
      period: "",
      installmentsNumber: 1,
      installments: [
        {
          price: "",
          paymentDate: ""
        }
      ],
      paymentFrequency: "month",
      advisor: "",
      risksOnPolicy: []
    },
    client: {
      insurer: {
        isActive: true,
        roles: ["insurer"],
        legalForm: "",
        withoutPesel: false,
        pesel: "",
        firstName: "",
        lastName: "",
        birthDate: "",
        gender: "",
        phoneNumber: "",
        phoneNumberPrefix: "+48",
        withoutEmail: false,
        email: "",
        companyName: "",
        nip: "",
        regon: ""
      },
      insured: {
        isActive: true,
        roles: ["insured"],
        legalForm: "",
        withoutPesel: false,
        pesel: "",
        firstName: "",
        lastName: "",
        birthDate: "",
        gender: "",
        phoneNumber: "",
        phoneNumberPrefix: "+48",
        withoutEmail: false,
        email: "",
        companyName: "",
        nip: "",
        regon: "",
        children: []
      },
      user: {
        isActive: true,

        roles: ["user"],
        legalForm: "",
        withoutPesel: false,
        pesel: "",
        firstName: "",
        lastName: "",
        birthDate: "",
        gender: "",
        phoneNumber: "",
        phoneNumberPrefix: "+48",
        withoutEmail: false,
        email: "",
        companyName: "",
        nip: "",
        regon: ""
      },
      owner: {
        isActive: true,

        roles: ["owner"],
        legalForm: "",
        withoutPesel: false,
        pesel: "",
        firstName: "",
        lastName: "",
        birthDate: "",
        gender: "",
        phoneNumber: "",
        phoneNumberPrefix: "+48",
        withoutEmail: false,
        email: "",
        companyName: "",
        nip: "",
        regon: ""
      },
      leasingOwner: {
        isActive: true,

        roles: ["leasingOwner"],
        regon: "",
        companyName: "",
        nip: "",
        city: ""
      },
      coOwners: {
        coOwnersCount: 0,
        coOwnersArray: []
      }
    },
    rodo: {},
    insuranceObject: {
      vehicle: {
        type: "",
        vin: "",
        registrationNumber: "",
        firstRegistrationDate: "",
        make: "",
        productionYear: "",
        fuelType: "E",
        engineCapacity: "",
        numberOfDoors: "",
        enginePowerKM: "",
        bodyType: "",
        infoEkspertModel: "",
        euroTaxModel: "",
        otherIdentityNumber: "",
        otherType: "",
        otherMake: "",
        otherModel: ""
      },
      house: {
        street: "",
        houseNumber: "",
        apartmentNumber: "",
        postalCode: "",
        city: "",
        cessionEnabled: false,
        cessionBank: ""
      }
    },
    idd: {
      issue: {
        issueId: ""
      },
      statement: {
        workWithClientType: "",
        apk: "",
        type: "",
        meetingDate: "",
        status: "empty", // empty | verifing | waitingForClientConfirmation | confirmedByClient
        scanFile: null
      }
    }
  };
};

export const INITIAL_FORM_STATE = () => {
  return {
    formState: null,
    basic: {
      formState: null
    },
    policy: {
      formState: null,
      isRisksOnPolicyVisible: false
    },
    client: {
      formState: null,
      currentStep: "insurer",
      isLeasing: false,
      insurer: {
        formState: null,
        isLoaded: null
      },
      insured: {
        formState: null,
        isLoaded: null,
        hasChildren: false
      },
      owner: {
        formState: null,
        isLoaded: null
      },
      coOwners: {
        isLoaded: null,
        formState: null
      },
      leasingOwner: {
        formState: null,
        isLoaded: null
      },
      user: {
        formState: null,
        isLoaded: null
      }
    },
    rodo: {
      formState: null
    },
    insuranceObject: {
      vehicle: {
        formState: null,
        isLoaded: null,
        isAddNewVisible: false,
        isFiltersVisible: false,
        areVehiclesFound: false
      },
      house: {
        formState: null,
        isLoaded: null,
        isAddNewVisible: false,
        areAddressesFound: false
      }
    },
    idd: {
      formState: null,
      activeTab: "issue",
      issue: {
        formState: null,
        isLoaded: null
      },
      statement: {
        formState: null,
        isLoaded: false,
        isMailSent: false,
        isIddGenerated: false
      }
    }
  };
};
