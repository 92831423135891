import { DateTime } from "luxon";
import { CONSTANTS } from "@/core/helpers/Constants";
export const PeselDecode = pesel => {
  let year = parseInt(pesel.substring(0, 2), 10);
  let month = parseInt(pesel.substring(2, 4), 10);
  let day = parseInt(pesel.substring(4, 6), 10);
  if (month > 80) {
    year = year + 1800;
    month = month - 80;
  } else if (month > 60) {
    year = year + 2200;
    month = month - 60;
  } else if (month > 40) {
    year = year + 2100;
    month = month - 40;
  } else if (month > 20) {
    year = year + 2000;
    month = month - 20;
  } else {
    year += 1900;
  }
  const birthDate = DateTime.local(year, month, day);

  let gender = "";
  if (parseInt(pesel.substring(9, 10), 10) % 2 === 1) {
    gender = "male";
  } else {
    gender = "female";
  }
  return {
    gender: gender,
    birthDate: birthDate.toFormat(CONSTANTS().dateYmdFormat)
  };
};
