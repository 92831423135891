<template>
  <div>
    <div class="form-group" style="margin-bottom: 40px">
      <div class="form-check-buttons">
        <div v-for="option in insuranceGroups" :key="option.uuid" class="check">
          <input
            :id="option.uuid"
            v-model="form.type"
            type="radio"
            name="policyType"
            :value="option.uuid"
            @change="handleChangeType"
          />
          <label :for="option.uuid">{{ option.short_name }}</label>
        </div>
      </div>
      <div v-if="isFieldInvalid('type')" class="invalid-feedback">
        Uzupełnij to pole
      </div>
    </div>
    <div class="row">
      <div class="col-lg-6">
        <div class="form-group" :class="isFieldInvalid('insurer') && 'invalid'">
          <Select
            id="insurer"
            v-model="form.insurer"
            :options="insurersForSelect"
            label="Ubezpieczyciel"
            name="insurer"
            @onChange="handleChangeInsurers"
          />
          <div v-if="isFieldInvalid('insurer')" class="invalid-feedback">
            Uzupełnij to pole
          </div>
        </div>
      </div>
    </div>
    <div class="row">
      <div class="col-lg-6">
        <div
          class="form-group"
          :class="isFieldInvalid('productType') && 'invalid'"
        >
          <Select
            id="productType"
            v-model="form.productType"
            :options="productTypesForSelect"
            label="Rodzaj produktu"
            name="productType"
          />
          <div v-if="isFieldInvalid('productType')" class="invalid-feedback">
            Uzupełnij to pole
          </div>
        </div>
      </div>
    </div>
    <div class="row">
      <div class="col-lg-6">
        <div
          class="form-group mb-0"
          :class="isFieldInvalid('policyNumber') && 'invalid'"
        >
          <label for="policyNumber">Nr polisy/wniosku</label>
          <input
            id="policyNumber"
            v-model="form.policyNumber"
            type="text"
            class="form-control"
            name="policyNumber"
          />
          <div v-if="isFieldInvalid('policyNumber')" class="invalid-feedback">
            Uzupełnij to pole
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import {
  INSURANCE_GROUPS_LIST,
  INSURER_LIST,
  PRODUCT_TYPES_LIST
} from "@/core/services/store/insurer.module.js";
import { mapGetters } from "vuex";
import Select from "@/view/content/components/select/Select";
export default {
  name: "Basic",
  components: { Select },
  props: ["v", "resetForm", "form"],
  computed: {
    ...mapGetters([
      "insuranceGroups",
      "insurers",
      "productTypes",
      "isInsuranceGroupListLoading"
    ]),
    insurersForSelect() {
      const insurersForSelect = [];
      for (const insurer of this.insurers) {
        let ins = {
          text: insurer.name,
          value: insurer.uuid
        };
        insurersForSelect.push(ins);
      }
      return insurersForSelect;
    },
    productTypesForSelect() {
      const productTypesForSelect = [];
      for (const productType of this.productTypes) {
        let ins = {
          text: productType.name,
          value: productType.uuid
        };
        productTypesForSelect.push(ins);
      }
      return productTypesForSelect;
    }
  },
  watch: {
    v: {
      handler() {
        this.$nextTick(() => {
          for (let item in this.v) {
            if (this.v[item].hasOwnProperty("$reset")) {
              if (
                (this.v[item].$model !== "" && this.v[item].$model !== null) ||
                (this.v[item].$dirty &&
                  !this.v[item].$error &&
                  this.v[item].$model === "" &&
                  this.v[item].$model === null)
              ) {
                this.v[item].$reset();
              }
            }
          }
        });
      },
      deep: true
    }
  },
  mounted() {
    this.$store.dispatch(INSURANCE_GROUPS_LIST);
  },
  methods: {
    isFieldInvalid(field) {
      return this.v[field].$dirty && this.v[field].$invalid;
    },
    handleChangeType() {
      this.resetForm();
      this.$store.dispatch(INSURER_LIST, {
        insuranceGroup: this.form.type
      });
    },
    handleChangeInsurers() {
      this.$store.dispatch(PRODUCT_TYPES_LIST, {
        insuranceGroup: this.form.type,
        insurer: this.form.insurer
      });
    }
  }
};
</script>
