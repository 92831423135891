export const CONSTANTS = () => {
  return {
    dateYmdFormat: "dd-LL-yyyy",
    dateYmdMask: "##-##-####",
    dateYmdDefault: "00-00-0000",
    dateYFormat: "yyyy",
    dateYMask: "####",
    dateYDefault: "0000",
    policyTypeAuto: "1Q9DGW1KX09YR8CRDATGJJ1VXE",
    policyTypeLife: "473CKK11JE9YCS8GVDMM3991TJ",
    policyTypeProperty: "5BNTWMVPMX9ZXSBX2DBACSVD0M"
  };
}