<template>
  <fieldset :disabled="isVehicleChecking">
    <div style="margin-top: 40px">
      <div class="row">
        <div class="col-lg-6">
          <div class="form-group" :class="isFieldInvalid('type') && 'invalid'">
            <Select
              id="type"
              v-model="form.type"
              :options="vehicleTypes"
              label="Rodzaj pojazdu"
              name="type"
            />
            <div v-if="isFieldInvalid('type')" class="invalid-feedback">
              Uzupełnij to pole
            </div>
          </div>
        </div>
      </div>
      <template v-if="form.type !== 'inne'">
        <div class="d-flex align-items-center erasable-input-group">
          <div
            class="form-group"
            :class="(isFieldInvalid('vin') || isVinInvalid()) && 'invalid'"
          >
            <label for="vin">Numer VIN</label>
            <input
              id="vin"
              v-model="form.vin"
              v-mask="'XXXXXXXXXXXXXXXXX'"
              type="text"
              class="form-control"
              name="vin"
            />
            <div
              v-if="isFieldInvalid('vin') || isVinInvalid()"
              class="invalid-feedback"
            >
              <template v-if="isFieldInvalid('vin')">
                Uzupełnij to pole
              </template>
              <template v-else-if="isVinInvalid()">
                Nieprawidłowy VIN
              </template>
            </div>
          </div>
          <div v-if="form.vin !== ''" class="erase-icon">
            <img
              alt=""
              class="cursor-pointer"
              src="/media/components/form/form-erase.svg"
              @click="handleErase('vin')"
            />
          </div>
        </div>
        <SearchGroup
          :is-loaded="state.isLoaded"
          :is-loading="isVehicleChecking"
          error-icon="form-declined-fill"
          error-text="Uzupełnij dane pojazdu"
          success-icon="form-approved"
          success-text="Dane pojazdu zostały uzupełnione"
          @handleSearch="handleSearchVehicle"
        />
        <hr class="installments-divider" />
        <div class="row">
          <div class="col-lg-6">
            <div
              class="form-group"
              :class="isFieldInvalid('registrationNumber') && 'invalid'"
            >
              <label for="registrationNumber">Numer rejestracyjny</label>
              <input
                id="registrationNumber"
                v-model="form.registrationNumber"
                type="text"
                class="form-control"
                name="registrationNumber"
              />
              <div
                v-if="isFieldInvalid('registrationNumber')"
                class="invalid-feedback"
              >
                Uzupełnij to pole
              </div>
            </div>
          </div>
        </div>
        <div class="row">
          <div class="col-lg-6">
            <div
              class="form-group"
              :class="isFieldInvalid('firstRegistrationDate') && 'invalid'"
            >
              <label for="firstRegistrationDate">Data I rejestracji</label>
              <Datepicker
                id="firstRegistrationDate"
                :form="form"
                :opened="openFirstRegistrationDate"
                :disabled-date="disabledFirstRegistrationDate"
                name="firstRegistrationDate"
                @onChange="
                  handleChangeDate($event.target.value, 'firstRegistrationDate')
                "
                @openCalendar="handleOpenFirstRegistrationDate"
              />
              <div
                v-if="isFieldInvalid('firstRegistrationDate')"
                class="invalid-feedback"
              >
                Uzupełnij to pole
              </div>
            </div>
          </div>
        </div>
        <div class="row">
          <div class="col-lg-6">
            <div
              class="form-group"
              :class="isFieldInvalid('make') && 'invalid'"
            >
              <Select
                id="make"
                v-model="form.make"
                :options="vehicleMakes"
                label="Marka"
                name="make"
              />
              <div v-if="isFieldInvalid('make')" class="invalid-feedback">
                Uzupełnij to pole
              </div>
            </div>
          </div>
        </div>
        <div class="row">
          <div class="col-lg-6">
            <div
              class="form-group"
              :class="isFieldInvalid('productionYear') && 'invalid'"
            >
              <label for="productionYear">Rok produkcji</label>
              <Datepicker
                id="productionYear"
                :form="form"
                :opened="openProductionYearDate"
                format="yyyy"
                type="year"
                :disabled-date="disabledProductionYears"
                name="productionYear"
                @onChange="handleChangeProductionYear($event.target.value)"
                @openCalendar="handleOpenProductionYear"
              />
              <div
                v-if="isFieldInvalid('productionYear')"
                class="invalid-feedback"
              >
                Uzupełnij to pole
              </div>
            </div>
          </div>
        </div>
        <div class="row">
          <div class="col-lg-6">
            <div
              class="form-group"
              :class="isFieldInvalid('fuelType') && 'invalid'"
            >
              <Select
                id="fuelType"
                v-model="form.fuelType"
                :options="vehicleFuelTypes"
                label="Rodzaj paliwa"
                name="fuelType"
              />
              <div v-if="isFieldInvalid('fuelType')" class="invalid-feedback">
                Uzupełnij to pole
              </div>
            </div>
          </div>
        </div>
        <div class="row">
          <div class="col-lg-6">
            <div
              class="form-group"
              :class="isFieldInvalid('engineCapacity') && 'invalid'"
            >
              <Select
                id="engineCapacity"
                v-model="form.engineCapacity"
                :options="vehicleEngineCapacities"
                label="Pojemność silnika"
                name="engineCapacity"
              />
              <div
                v-if="isFieldInvalid('engineCapacity')"
                class="invalid-feedback"
              >
                Uzupełnij to pole
              </div>
            </div>
          </div>
        </div>
        <div class="row">
          <div class="col-lg-6">
            <div
              class="form-group"
              :class="isFieldInvalid('numberOfDoors') && 'invalid'"
              style="margin-bottom: 25px"
            >
              <Select
                id="numberOfDoors"
                v-model="form.numberOfDoors"
                :options="vehicleNumbersOfDoor"
                label="Liczba drzwi"
                name="numberOfDoors"
              />
              <div
                v-if="isFieldInvalid('numberOfDoors')"
                class="invalid-feedback"
              >
                Uzupełnij to pole
              </div>
            </div>
          </div>
        </div>
        <div :style="showVehicleTables && 'margin-bottom: 55px'">
          <Collapse
            ref="collapse-filters"
            :collapsed="state.isFiltersVisible"
            title-color="secondary"
            @onCollapse="handleCollapseFilters"
          >
            <template v-slot:heading>Filtry</template>
            <template v-slot:content>
              <div style="margin-top: 20px">
                <div class="row">
                  <div class="col-lg-6">
                    <div
                      class="form-group"
                      :class="isFieldInvalid('enginePowerKM') && 'invalid'"
                    >
                      <Select
                        id="enginePowerKM"
                        v-model="form.enginePowerKM"
                        :options="vehicleEnginePowersKM"
                        label="Moc silnika"
                        name="enginePowerKM"
                      />
                      <div
                        v-if="isFieldInvalid('enginePowerKM')"
                        class="invalid-feedback"
                      >
                        Uzupełnij to pole
                      </div>
                    </div>
                  </div>
                </div>
                <div class="row">
                  <div class="col-lg-6">
                    <div
                      class="form-group mb-0"
                      :class="isFieldInvalid('bodyType') && 'invalid'"
                    >
                      <Select
                        id="bodyType"
                        v-model="form.bodyType"
                        :options="vehicleBodyTypes"
                        label="Rodzaj nadwozia"
                        name="bodyType"
                      />
                      <div
                        v-if="isFieldInvalid('bodyType')"
                        class="invalid-feedback"
                      >
                        Uzupełnij to pole
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </template>
          </Collapse>
        </div>
        <template v-if="showVehicleTables">
          <CheckTable
            :items="infoEkspertModels"
            :title="'Wybierz model InfoEkspert'"
            title-style="mb-0"
            :v="v"
            :validate-state="isFieldInvalid('infoEkspertModel')"
            field="infoEkspertModel"
          />
          <div style="margin-top: 40px">
            <CheckTable
              :items="euroTaxModels"
              :title="'Wybierz model Eurotax'"
              title-style="mb-0"
              :v="v"
              :validate-state="isFieldInvalid('euroTaxModel')"
              field="euroTaxModel"
            />
          </div>
        </template>
      </template>
      <template v-else>
        <div class="d-flex align-items-center erasable-input-group">
          <div
            class="form-group"
            :class="isFieldInvalid('otherIdentityNumber') && 'invalid'"
          >
            <label for="otherIdentityNumber">Numer identyfikacyjny</label>
            <input
              id="otherIdentityNumber"
              v-model="form.otherIdentityNumber"
              type="text"
              class="form-control"
              name="otherIdentityNumber"
            />
            <div
              v-if="isFieldInvalid('otherIdentityNumber')"
              class="invalid-feedback"
            >
              Uzupełnij to pole
            </div>
          </div>
          <div v-if="form.otherIdentityNumber !== ''" class="erase-icon">
            <img
              alt=""
              class="cursor-pointer"
              src="/media/components/form/form-erase.svg"
              @click="handleErase('otherIdentityNumber')"
            />
          </div>
        </div>
        <SearchGroup
          :is-loaded="state.isLoaded"
          :is-loading="isVehicleChecking"
          error-icon="form-declined-fill"
          error-text="Uzupełnij dane pojazdu"
          success-icon="form-approved"
          success-text="Dane pojazdu zostały uzupełnione"
          @handleSearch="handleSearchOtherVehicle"
        />
        <div class="row">
          <div class="col-lg-6">
            <div class="form-group">
              <label for="otherType">Typ pojazdu</label>
              <input
                id="otherType"
                v-model="form.otherType"
                type="text"
                class="form-control"
                name="otherType"
              />
              <div v-if="isFieldInvalid('otherType')" class="invalid-feedback">
                Uzupełnij to pole
              </div>
            </div>
          </div>
        </div>
        <div class="row">
          <div class="col-lg-6">
            <div class="form-group">
              <label for="otherMake">Marka</label>
              <input
                id="otherMake"
                v-model="form.otherMake"
                type="text"
                class="form-control"
                name="otherMake"
              />
              <div v-if="isFieldInvalid('otherMake')" class="invalid-feedback">
                Uzupełnij to pole
              </div>
            </div>
          </div>
        </div>
        <div class="row">
          <div class="col-lg-6">
            <div class="form-group mb-0">
              <label for="otherMake">Model</label>
              <input
                id="otherModel"
                v-model="form.otherModel"
                type="text"
                class="form-control"
                name="otherModel"
              />
              <div v-if="isFieldInvalid('otherModel')" class="invalid-feedback">
                Uzupełnij to pole
              </div>
            </div>
          </div>
        </div>
      </template>
    </div>
  </fieldset>
</template>

<script>
import { DateTime } from "luxon";
import { CONSTANTS } from "@/core/helpers/Constants";
import SearchGroup from "@/view/content/components/search-group/SearchGroup";
import Datepicker from "@/view/content/components/date-picker/Datepicker";
import Collapse from "@/view/content/components/collapse/Collapse";
import CheckTable from "@/view/content/components/table/CheckTable";
import Select from "@/view/content/components/select/Select";

export default {
  name: "VehicleForm",
  components: { Select, CheckTable, Collapse, SearchGroup, Datepicker },
  props: ["v", "form", "state", "chosenVehicleId"],
  data() {
    return {
      vehicleTypes: [
        { text: "Wybierz opcję", value: "" },
        { text: "osobowe", value: "osobowe" },
        { text: "inne", value: "inne" }
      ],
      vehicleMakes: [
        { text: "Wybierz opcję", value: "" },
        { text: "Audi", value: 7 }
      ],
      vehicleFuelTypes: [
        { text: "Wybierz opcję", value: "" },
        { text: "Benzyna", value: "E" }
      ],
      vehicleEngineCapacities: [
        { text: "Wybierz opcję", value: "" },
        { text: "1984", value: 1984 },
        { text: "2498", value: 2498 }
      ],
      vehicleNumbersOfDoor: [
        { text: "Wybierz opcję", value: "" },
        { text: "3", value: 3 },
        { text: "4", value: 4 },
        { text: "5", value: 5 }
      ],
      vehicleEnginePowersKM: [
        { text: "Wybierz opcję", value: "" },
        { text: "75", value: 75 },
        { text: "110", value: 110 }
      ],
      vehicleBodyTypes: [
        { text: "Wybierz opcję", value: "" },
        { text: "sedan", value: "sedan" },
        { text: "hatchback", value: "hatchback" }
      ],
      isVehicleChecking: false,
      openFirstRegistrationDate: false,
      openProductionYearDate: false,
      infoEkspertModels: {
        headers: [
          {
            key: "name",
            text: "",
            style: "width: 400px;"
          },
          {
            key: "dmc",
            text: "DMC",
            style: "width: 50px; text-align: center; margin-left: 50px"
          },
          {
            key: "moc",
            text: "MOC",
            style: "width: 50px; text-align: center; margin-left: 50px;"
          },
          {
            key: "typ",
            text: "TYP",
            style: "width: 50px; text-align: center; margin-left: 50px;"
          }
        ],
        items: [
          {
            id: {
              key: "id",
              value: 1
            },
            name: {
              key: "name",
              value: "A3 35 TFSI MR`20 8Y Edition one (5)"
            },
            dmc: {
              key: "dmc",
              value: 1865
            },
            moc: {
              key: "moc",
              value: 110
            },
            typ: {
              key: "typ",
              value: "sedan"
            }
          },
          {
            id: {
              key: "id",
              value: 2
            },
            name: {
              key: "name",
              value: "A3 35 TFSI MR`20 8Y Edition one S tronic (5)"
            },
            dmc: {
              key: "dmc",
              value: 1865
            },
            moc: {
              key: "moc",
              value: 110
            },
            typ: {
              key: "typ",
              value: "sedan"
            }
          },
          {
            id: {
              key: "id",
              value: 3
            },
            name: {
              key: "name",
              value: "A3 35 TFSI MR`20 8Y (5)"
            },
            dmc: {
              key: "dmc",
              value: 1865
            },
            moc: {
              key: "moc",
              value: 105
            },
            typ: {
              key: "typ",
              value: "sedan"
            }
          },
          {
            id: {
              key: "id",
              value: 4
            },
            name: {
              key: "name",
              value: "A3 35 TFSI MR`20 8Y Edition one (5)"
            },
            dmc: {
              key: "dmc",
              value: 1765
            },
            moc: {
              key: "moc",
              value: 105
            },
            typ: {
              key: "typ",
              value: "sedan"
            }
          },
          {
            id: {
              key: "id",
              value: 5
            },
            name: {
              key: "name",
              value: "A3 35 TFSI MR`20 8Y Edition one S tronic (5)"
            },
            dmc: {
              key: "dmc",
              value: 2500
            },
            moc: {
              key: "moc",
              value: 120
            },
            typ: {
              key: "typ",
              value: "sedan"
            }
          },
          {
            id: {
              key: "id",
              value: 6
            },
            name: {
              key: "name",
              value: "A3 35 TFSI MR`20 8Y (5)"
            },
            dmc: {
              key: "dmc",
              value: 1865
            },
            moc: {
              key: "moc",
              value: 120
            },
            typ: {
              key: "typ",
              value: "sedan"
            }
          },
          {
            id: {
              key: "id",
              value: -1
            },
            name: {
              key: "name",
              value: "Brak modelu w katalogu",
              style: {
                fontStyle: "italic"
              }
            },
            dmc: {
              key: "dmc",
              value: ""
            },
            moc: {
              key: "moc",
              value: ""
            },
            typ: {
              key: "typ",
              value: ""
            }
          }
        ]
      },
      euroTaxModels: {
        headers: [
          {
            key: "name",
            text: "",
            style: "width: 200px"
          },
          {
            key: "version",
            text: "WERSJA",
            style: "width: 200px; margin-left: 50px;"
          },
          {
            key: "moc",
            text: "MOC",
            style: "width: 50px; text-align: center; margin-left: 50px;"
          },
          {
            key: "typ",
            text: "TYP",
            style: "width: 50px; text-align: center; margin-left: 50px;"
          }
        ],
        items: [
          {
            id: {
              key: "id",
              value: 1
            },
            name: {
              key: "name",
              value: "A3 Limousine [8V] 16-"
            },
            version: {
              key: "version",
              value: "A3 1.5 TFSI Sport S tronic (5)"
            },
            moc: {
              key: "moc",
              value: 110
            },
            typ: {
              key: "typ",
              value: "sedan"
            }
          },
          {
            id: {
              key: "id",
              value: 2
            },
            name: {
              key: "name",
              value: "A3 Limousine [8V] 16-"
            },
            version: {
              key: "version",
              value: "A3 1.5 TFSI (5)"
            },
            moc: {
              key: "moc",
              value: 110
            },
            typ: {
              key: "typ",
              value: "sedan"
            }
          },
          {
            id: {
              key: "id",
              value: 3
            },
            name: {
              key: "name",
              value: "A3 Limousine [8V] 16-"
            },
            version: {
              key: "version",
              value: "A3 1.5 TFSI Sport (5)"
            },
            moc: {
              key: "moc",
              value: 105
            },
            typ: {
              key: "typ",
              value: "sedan"
            }
          },
          {
            id: {
              key: "id",
              value: 4
            },
            name: {
              key: "name",
              value: "A3 Limousine [8V] 16-"
            },
            version: {
              key: "version",
              value: "A3 1.5 TFSI S tronic (5)"
            },
            moc: {
              key: "moc",
              value: 105
            },
            typ: {
              key: "typ",
              value: "sedan"
            }
          },
          {
            id: {
              key: "id",
              value: -1
            },
            name: {
              key: "name",
              value: "Brak modelu w katalogu",
              style: {
                fontStyle: "italic"
              }
            },
            version: {
              key: "version",
              value: ""
            },
            moc: {
              key: "moc",
              value: ""
            },
            typ: {
              key: "typ",
              value: ""
            }
          }
        ]
      }
    };
  },
  computed: {
    disabledProductionYears() {
      const frD = DateTime.fromFormat(
        this.form.firstRegistrationDate,
        CONSTANTS().dateYmdFormat
      );
      if (!frD.isValid) {
        return {
          min: null,
          max: DateTime.local()
        };
      }
      return {
        min: DateTime.fromObject({ year: frD.year - 1, month: 1, day: 1 }),
        max: DateTime.local()
      };
    },
    disabledFirstRegistrationDate() {
      return {
        min: null,
        max: DateTime.local()
      };
    },
    showVehicleTables() {
      return (
        this.form.vin !== "" &&
        this.form.registrationNumber !== "" &&
        this.form.type !== "" &&
        this.form.productionYear !== "" &&
        this.form.fuelType !== "" &&
        this.form.engineCapacity !== "" &&
        this.form.numberOfDoors !== ""
      );
    }
  },
  watch: {
    v: {
      handler() {
        this.$nextTick(() => {
          for (let item in this.v) {
            if (this.v[item].hasOwnProperty("$reset")) {
              if (
                (this.v[item].$model !== "" && this.v[item].$model !== null) ||
                (this.v[item].$dirty &&
                  !this.v[item].$error &&
                  this.v[item].$model === "" &&
                  this.v[item].$model === null)
              ) {
                this.v[item].$reset();
              }
            }
          }
        });
      },
      deep: true
    },
    form: {
      handler() {
        if (this.showVehicleTables && this.chosenVehicleId === -1) {
          this.$nextTick(() => {
            this.$root.$emit("updateSection", "collapse-add-new-vehicle");
          });
        }
      },
      deep: true
    }
  },
  methods: {
    resetVehicle() {
      this.form.type = "";
      this.form.vin = "";
      this.form.registrationNumber = "";
      this.form.firstRegistrationDate = "";
      this.form.make = "";
      this.form.productionYear = "";
      this.form.fuelType = "";
      this.form.engineCapacity = "";
      this.form.numberOfDoors = "";
      this.form.enginePowerKM = "";
      this.form.bodyType = "";
      this.form.infoEkspertModel = "";
      this.form.euroTaxModel = "";
      this.form.otherIdentityNumber = "";
      this.form.otherType = "";
      this.form.otherMake = "";
      this.form.otherModel = "";

      this.state.isLoaded = null;
    },
    handleOpenFirstRegistrationDate() {
      this.openFirstRegistrationDate = true;
    },
    handleOpenProductionYear() {
      this.openProductionYearDate = true;
    },
    handleErase(field) {
      this.form[field] = "";
      if (field === "vin") {
        this.resetVehicle();
      }
    },
    isFieldInvalid(field) {
      return this.v[field].$dirty && this.v[field].$invalid;
    },
    isVinInvalid() {
      return !this.v.vin.isValid;
    },
    handleChangeDate(value, field) {
      this.form[field] = value
        ? DateTime.fromFormat(value, CONSTANTS().dateYmdFormat).toFormat(
            CONSTANTS().dateYmdFormat
          )
        : "";
    },
    handleChangeProductionYear(value) {
      this.form.productionYear = value
        ? DateTime.fromFormat(value, CONSTANTS().dateYFormat)
        : null;
    },
    handleCollapseFilters() {
      this.state.isFiltersVisible = !this.state.isFiltersVisible;
      this.$nextTick(() => {
        if (this.chosenVehicleId === -1) {
          this.$root.$emit("updateSection", "collapse-add-new-vehicle");
        }
      });
    },
    handleSearchVehicle() {
      this.isVehicleChecking = true;
      setTimeout(() => {
        if (
          this.form.vin === "11111111111111111" &&
          this.form.type === "osobowe"
        ) {
          this.form.type = "osobowe";
          this.form.registrationNumber = "OP11111";
          this.form.firstRegistrationDate = "19-01-2011";
          this.form.make = 7;
          this.form.productionYear = "2011";
          this.form.fuelType = "E";
          this.form.engineCapacity = 1984;
          this.form.numberOfDoors = 4;
          this.form.enginePowerKM = 75;
          this.form.bodyType = "sedan";
          this.form.infoEkspertModel = 1;
          this.form.euroTaxModel = 1;
          this.form.otherIdentityNumber = "";
          this.form.otherType = "";
          this.form.otherMake = "";
          this.form.otherModel = "";
          this.state.isLoaded = true;
        } else {
          this.state.isLoaded = false;
        }
        this.isVehicleChecking = false;
      }, 1500);
    },
    handleSearchOtherVehicle() {
      this.isVehicleChecking = true;
      setTimeout(() => {
        if (
          this.form.otherIdentityNumber === "12345" &&
          this.form.type === "inne"
        ) {
          this.form.otherType = "osobowe";
          this.form.otherMake = 7;
          this.form.otherModel = "Model";
          this.state.isLoaded = true;
        } else {
          this.state.isLoaded = false;
        }
        this.isVehicleChecking = false;
      }, 1500);
    }
  }
};
</script>
