<template>
  <fieldset :disabled="isClientChecking">
    <div class="d-flex align-items-center erasable-input-group">
      <div
        class="form-group"
        :class="(isFieldInvalid('pesel') || isPeselInvalid()) && 'invalid'"
      >
        <label for="pesel">PESEL</label>
        <input
          id="pesel"
          v-model="form.pesel"
          v-mask="'###########'"
          type="text"
          class="form-control"
          name="pesel"
          @change="handleChangePesel"
          @input="
            () => {
              if (state.isLoaded === true) {
                state.isLoaded = null;
              }
            }
          "
        />
        <div
          v-if="isFieldInvalid('pesel') || isPeselInvalid()"
          class="invalid-feedback"
        >
          <template v-if="isFieldInvalid('pesel')">
            Uzupełnij to pole
          </template>
          <template v-else-if="isPeselInvalid()">
            Nieprawidłowy PESEL
          </template>
        </div>
      </div>
      <div v-if="form.pesel !== ''" class="erase-icon">
        <img
          alt=""
          class="cursor-pointer"
          src="/media/components/form/form-erase.svg"
          @click="handleErase('pesel')"
        />
      </div>
      <div class="check-buttons">
        <div class="check muted">
          <input
            :id="'withoutPesel-' + role"
            v-model="form.withoutPesel"
            :name="'withoutPesel-' + role"
            type="checkbox"
            @change="handleChangeWithoutPesel"
          />
          <label :for="'withoutPesel-' + role" class="text-muted"
            >Cudzoziemiec</label
          >
        </div>
      </div>
    </div>
    <template v-if="form.withoutPesel === true">
      <div class="row">
        <div class="col-lg-6">
          <div
            class="form-group"
            :class="isFieldInvalid('firstName') && 'invalid'"
          >
            <label for="firstName">Imię</label>
            <input
              id="firstName"
              v-model="form.firstName"
              class="form-control"
              name="firstName"
              type="text"
            />
            <div v-if="isFieldInvalid('firstName')" class="invalid-feedback">
              Uzupełnij to pole
            </div>
          </div>
        </div>
      </div>
      <div class="row">
        <div class="col-lg-6">
          <div
            class="form-group"
            :class="isFieldInvalid('lastName') && 'invalid'"
          >
            <label for="lastName">Nazwisko</label>
            <input
              id="lastName"
              v-model="form.lastName"
              class="form-control"
              name="lastName"
              type="text"
            />
            <div v-if="isFieldInvalid('lastName')" class="invalid-feedback">
              Uzupełnij to pole
            </div>
          </div>
        </div>
      </div>
    </template>
    <SearchGroup
      :is-loaded="state.isLoaded"
      :is-loading="isClientChecking"
      error-icon="form-declined-fill"
      error-text="Uzupełnij dane klienta"
      success-icon="form-approved"
      success-text="Dane klienta zostały uzupełnione"
      @handleSearch="handleSearchClient"
    />
    <hr class="installments-divider" />
    <div class="form-group" :class="isFieldInvalid('gender') && 'invalid'">
      <label>Płeć</label>
      <div class="form-check-buttons">
        <div class="check">
          <input
            :id="role + '-gender-female'"
            v-model="v.gender.$model"
            :disabled="form.pesel !== ''"
            :name="role + '-gender'"
            class="btn-check"
            type="radio"
            value="female"
          />
          <label :for="role + '-gender-female'">Kobieta</label>
        </div>
        <div class="check">
          <input
            :id="role + '-gender-male'"
            v-model="v.gender.$model"
            :disabled="form.pesel !== ''"
            :name="role + '-gender'"
            class="btn-check"
            type="radio"
            value="male"
          />
          <label :for="role + '-gender-male'">Mężczyzna</label>
        </div>
      </div>
      <div v-if="isFieldInvalid('gender')" class="invalid-feedback">
        Uzupełnij to pole
      </div>
    </div>
    <div class="row">
      <div class="col-lg-6">
        <div
          class="form-group"
          :class="isFieldInvalid('birthDate') && 'invalid'"
        >
          <label :for="'birthDate-' + role">Data urodzenia</label>
          <Datepicker
            :id="'birthDate-' + role"
            :form="form"
            :opened="openBirthDate && openBirthDateRole === role"
            name="birthDate"
            start-on="year"
            :disabled-date="disabledBirthDate"
            :disabled="form.pesel !== ''"
            @onChange="handleChangeDate($event.target.value, 'birthDate')"
            @openCalendar="handleOpenBirthDate"
          />
          <div v-if="isFieldInvalid('birthDate')" class="invalid-feedback">
            Uzupełnij to pole
          </div>
        </div>
      </div>
    </div>
    <template v-if="form.withoutPesel === false">
      <div class="row">
        <div class="col-lg-6">
          <div
            class="form-group"
            :class="isFieldInvalid('firstName') && 'invalid'"
          >
            <label for="firstName">Imię</label>
            <input
              id="firstName"
              v-model="form.firstName"
              class="form-control"
              name="firstName"
              type="text"
            />
            <div v-if="isFieldInvalid('firstName')" class="invalid-feedback">
              Uzupełnij to pole
            </div>
          </div>
        </div>
      </div>
      <div class="row">
        <div class="col-lg-6">
          <div
            class="form-group"
            :class="isFieldInvalid('lastName') && 'invalid'"
          >
            <label for="lastName">Nazwisko</label>
            <input
              id="lastName"
              v-model="form.lastName"
              class="form-control"
              name="lastName"
              type="text"
            />
            <div v-if="isFieldInvalid('lastName')" class="invalid-feedback">
              Uzupełnij to pole
            </div>
          </div>
        </div>
      </div>
    </template>
    <div class="row">
      <div class="col-lg-6">
        <div
          class="form-group"
          :class="
            (isFieldInvalid('phoneNumber') || isPhoneNumberInvalid()) &&
              'invalid'
          "
        >
          <label for="pesel">Numer telefonu</label>
          <div class="input-group">
            <button
              v-click-outside="hidePhonePrefix"
              class="btn btn-outline-gray-300 text-gray-900 px-2"
              type="button"
              @click.stop="openPhonePrefix = true"
            >
              {{ form.phoneNumberPrefix }}
              <img
                alt=""
                src="/media/components/form/form-angle-down.svg"
                style="margin-top: -2px; margin-left: 10px"
              />
            </button>
            <ul
              v-show="openPhonePrefix"
              class="dropdown-menu show"
              style="top: 35px"
            >
              <li>
                <div
                  class="dropdown-item cursor-pointer"
                  @click="
                    form.phoneNumberPrefix = '+48';
                    openPhonePrefix = false;
                  "
                >
                  +48
                </div>
              </li>
              <li>
                <div
                  class="dropdown-item cursor-pointer"
                  @click="
                    form.phoneNumberPrefix = '+49';
                    openPhonePrefix = false;
                  "
                >
                  +49
                </div>
              </li>
              <li>
                <div
                  class="dropdown-item cursor-pointer"
                  @click="
                    form.phoneNumberPrefix = '+50';
                    openPhonePrefix = false;
                  "
                >
                  +50
                </div>
              </li>
            </ul>
            <input
              id="phoneNumber"
              v-model="form.phoneNumber"
              v-mask="'###-###-###'"
              class="form-control"
              name="phoneNumber"
              type="text"
            />
          </div>

          <div
            v-if="isFieldInvalid('phoneNumber') || isPhoneNumberInvalid()"
            class="invalid-feedback"
          >
            <template v-if="isFieldInvalid('phoneNumber')">
              Uzupełnij to pole
            </template>
            <template v-else-if="isPhoneNumberInvalid()">
              Nieprawidłowy numer telefonu
            </template>
          </div>
        </div>
      </div>
    </div>
    <div class="d-flex align-items-center erasable-input-group">
      <div
        class="form-group mb-0"
        :class="(isFieldInvalid('email') || isEmailInvalid()) && 'invalid'"
      >
        <label for="email">E-mail</label>
        <input
          id="email"
          v-model="form.email"
          class="form-control"
          name="email"
          type="email"
        />
        <div
          v-if="isFieldInvalid('email') || isEmailInvalid()"
          class="invalid-feedback"
        >
          <template v-if="isFieldInvalid('email')">
            Uzupełnij to pole
          </template>
          <template v-else-if="isEmailInvalid()">
            Nieprawidłowy e-mail
          </template>
        </div>
      </div>
      <div v-if="form.email !== ''" class="erase-icon" style="margin-top: 25px">
        <img
          alt=""
          class="cursor-pointer"
          src="/media/components/form/form-erase.svg"
          @click="handleErase('email')"
        />
      </div>
      <div class="check-buttons" style="margin-top: 25px">
        <div class="check muted">
          <input
            :id="'withoutEmail-' + role"
            v-model="form.withoutEmail"
            :name="'withoutEmail-' + role"
            type="checkbox"
            @change="handleChangeWithoutEmail"
          />
          <label :for="'withoutEmail-' + role" class="text-muted"
            >Klient nie ma maila</label
          >
        </div>
      </div>
    </div>
  </fieldset>
</template>

<script>
import { DateTime } from "luxon";
import { PeselDecode } from "@/core/helpers/DecodePesel.js";
import Datepicker from "@/view/content/components/date-picker/Datepicker";
import SearchGroup from "@/view/content/components/search-group/SearchGroup";
import { CONSTANTS } from "@/core/helpers/Constants";

export default {
  name: "ClientPrivateForm",
  components: { SearchGroup, Datepicker },
  props: ["v", "form", "state", "role"],
  data() {
    return {
      openBirthDate: false,
      openBirthDateRole: -1,
      isClientChecking: false,
      openPhonePrefix: false
    };
  },
  computed: {
    disabledBirthDate() {
      return {
        min: null,
        max: DateTime.now()
      };
    }
  },
  watch: {
    v: {
      handler() {
        this.$nextTick(() => {
          for (let item in this.v) {
            if (this.v[item].hasOwnProperty("$reset")) {
              if (
                (this.v[item].$model !== "" && this.v[item].$model !== null) ||
                (this.v[item].$dirty &&
                  !this.v[item].$error &&
                  this.v[item].$model === "" &&
                  this.v[item].$model === null)
              ) {
                this.v[item].$reset();
              }
            }
          }
        });
      },
      deep: true
    }
  },
  methods: {
    resetClient() {
      this.form.pesel = "";
      this.form.gender = "";
      this.form.firstName = "";
      this.form.lastName = "";
      this.form.birthDate = "";
      this.form.phoneNumber = "";
      this.form.email = "";

      this.state.isLoaded = null;
    },
    handleErase(field) {
      this.form[field] = "";
      if (field === "pesel") {
        this.resetClient();
      }
    },
    hidePhonePrefix() {
      this.openPhonePrefix = false;
    },
    isFieldInvalid(field) {
      return this.v[field].$dirty && this.v[field].$invalid;
    },
    isPeselInvalid() {
      return !this.v.pesel.isValid;
    },
    isPhoneNumberInvalid() {
      return !this.v.phoneNumber.minLength || !this.v.phoneNumber.maxLength;
    },
    isEmailInvalid() {
      return !this.v.email.isValid;
    },
    handleChangeDate(value, field) {
      this.form[field] = value
        ? DateTime.fromFormat(value, CONSTANTS().dateYmdFormat).toFormat(
            CONSTANTS().dateYmdFormat
          )
        : "";
    },
    handleOpenBirthDate() {
      this.openBirthDate = true;
      this.openBirthDateRole = this.role;
    },
    handleDecodePesel(pesel) {
      const decodedPesel = PeselDecode(pesel);
      this.form.birthDate = decodedPesel.birthDate;
      this.form.gender = decodedPesel.gender;
    },
    handleChangePesel() {
      if (this.form.pesel !== "") {
        this.handleDecodePesel(this.form.pesel);
      }
    },
    handleChangeWithoutPesel() {
      if (this.form.withoutPesel) {
        this.resetClient();
      }
    },
    handleChangeWithoutEmail() {
      if (this.form.withoutEmail) {
        this.form.email = "";
      }
    },
    handleSearchClient() {
      this.isClientChecking = true;
      setTimeout(() => {
        if (this.form.withoutPesel === false) {
          if (this.form.pesel !== "") {
            this.handleDecodePesel(this.form.pesel);
          }
          if (this.form.pesel === "11111111111") {
            this.form.firstName = "Jan";
            this.form.lastName = "Kowalski";
            this.form.phoneNumber = "123 456 789";
            this.form.email = "jan.kowalski@domena.pl";
            this.state.isLoaded = true;
          } else {
            this.state.isLoaded = false;
          }
          this.isClientChecking = false;
        } else {
          if (
            this.form.firstName === "Jan" &&
            this.form.lastName === "Kowalski"
          ) {
            this.form.birthDate = "12-04-1988";
            this.form.gender = "male";

            this.form.phoneNumber = "123 456 789";
            this.form.email = "jan.kowalski@domena.pl";
            this.state.isLoaded = true;
          } else {
            this.state.isLoaded = false;
          }
          this.isClientChecking = false;
        }
      }, 1000);
    }
  }
};
</script>
