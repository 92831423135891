<template>
  <date-picker
    v-model="field"
    :format="format"
    :formatter="luxonFormat"
    locale="pl"
    :type="type"
    :clearable="false"
    :open="isCalendarOpened"
    :disabled="disabled"
    :disabled-date="date => disabledDates(date)"
    :default-panel="startOn"
    @close="handleCloseCalendar"
    @click.native="handleOpenCalendar"
    @change="$emit('changeCalendar')"
  >
    <template v-slot:input="{ props, events }">
      <input
        :id="id"
        v-model="form[name]"
        v-mask="getFormatVMask"
        class="form-control"
        type="text"
        :name="name"
        :placeholder="getFormatPlaceholder"
        :disabled="disabled"
        @change="$emit('onChange', $event, name)"
      />
    </template>
    <template slot="icon-calendar">
      <img src="/media/components/form/calendar.svg" alt="" />
    </template>
  </date-picker>
</template>

<script>
import { CONSTANTS } from "@/core/helpers/Constants";
import { DateTime } from "luxon";
import DatePicker from "vue2-datepicker";
import "vue2-datepicker/index.css";
import "vue2-datepicker/locale/pl";

export default {
  name: "Datepicker",
  components: { DatePicker },
  props: {
    form: {
      type: Object,
      default: null
    },
    name: {
      type: String,
      default: ""
    },
    id: {
      type: String,
      default: ""
    },
    handleClick: {
      type: Function,
      default: null
    },
    handleOpen: {
      type: Function,
      default: null
    },
    opened: {
      type: Boolean,
      default: false
    },
    disabled: {
      type: Boolean,
      default: false
    },
    format: {
      type: String,
      default: CONSTANTS().dateYmdFormat
    },
    type: {
      type: String,
      default: "date"
    },
    // eslint-disable-next-line vue/require-prop-types
    disabledDate: {
      type: Object,
      default: null
    },
    startOn: {
      type: String,
      default: "day"
    }
  },
  data() {
    return {
      isCalendarOpened: this.opened,
      luxonFormat: {
        //[optional] Date to String
        stringify: date => {
          return date ? DateTime.fromJSDate(date).toFormat(this.format) : "";
        },
        //[optional]  String to Date
        parse: value => {
          return value
            ? DateTime.fromFormat(value, this.format).toJSDate()
            : null;
        }
      }
    };
  },
  computed: {
    field: {
      get() {
        return DateTime.fromFormat(
          this.form[this.name],
          this.format
        ).toJSDate();
      },
      set(newVal) {
        this.form[this.name] = DateTime.fromJSDate(newVal).toFormat(
          this.format
        );
      }
    },
    getFormatVMask() {
      return this.format === CONSTANTS().dateYmdFormat
        ? CONSTANTS().dateYmdMask
        : CONSTANTS().dateYMask;
    },
    getFormatPlaceholder() {
      return this.format === CONSTANTS().dateYmdFormat
        ? CONSTANTS().dateYmdDefault
        : CONSTANTS().dateYDefault;
    }
  },
  methods: {
    disabledDates(date) {
      return (
        this.disabledDate !== null &&
        ((this.disabledDate.min !== null && date < this.disabledDate.min) ||
          (this.disabledDate.max !== null && date > this.disabledDate.max))
      );
    },
    handleOpenCalendar(evt) {
      if (this.handleClick !== null) {
        this.$emit("openCalendar");
      } else {
        if (evt.target.tagName !== "INPUT") {
          this.isCalendarOpened = true;
        }
      }
    },
    handleCloseCalendar() {
      this.isCalendarOpened = false;
    }
  }
};
</script>
