import { render, staticRenderFns } from "./CheckTable.vue?vue&type=template&id=66e1d116&scoped=true&"
import script from "./CheckTable.vue?vue&type=script&lang=js&"
export * from "./CheckTable.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "66e1d116",
  null
  
)

export default component.exports