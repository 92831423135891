<template>
  <div>
    <div class="dev-check-buttons">
      <button
        class="btn btn-primary-light"
        type="button"
        @click="state.areAddressesFound = true"
      >
        Sprawdź 1
      </button>
      <button
        class="btn btn-primary-light"
        type="button"
        @click="state.areAddressesFound = false"
      >
        Sprawdź 2
      </button>
    </div>
    <p class="text-secondary" style="margin-bottom: 30px">
      Wybierz adres z listy lub uzupełnij dane
    </p>
    <template v-if="state.areAddressesFound">
      <div class="bordered-block" style="margin-bottom: 25px">
        <div class="radio-buttons">
          <template v-for="(address, index) in addresses">
            <div :key="index" class="radio">
              <input
                :id="'chosen-address' + index"
                :value="address"
                type="radio"
                name="chosen-address"
                :checked="chosenAddressId === address.id"
                @change="handleChangeAddress(address)"
              />
              <label :for="'chosen-address' + index">
                {{ address.postalCode }} {{ address.city }},
                {{ address.street }} {{ address.houseNumber }}
                <template v-if="address.apartmentNumber">{{
                  address.apartmentNumber
                }}</template>
              </label>
            </div>
          </template>
        </div>
      </div>
      <Collapse
        id="collapse-add-new-address"
        :collapsed="state.isAddNewVisible"
        @onCollapse="handleCollapseAddNewAddress"
      >
        <template v-slot:heading>Dodaj inny adres</template>
        <template v-slot:content>
          <AddressForm
            :v="v"
            :state="state"
            :form="form"
            :chosen-address-id="chosenAddressId"
          />
        </template>
      </Collapse>
    </template>
    <template v-else>
      <div class="bordered-block empty">
        <p class="font-italic text-secondary m-0">
          Brak adresów dla tego Klienta. Uzupełnij pola poniżej.
        </p>
      </div>
      <AddressForm
        :v="v"
        :state="state"
        :form="form"
        :chosen-address-id="chosenAddressId"
      />
    </template>
  </div>
</template>

<script>
import Collapse from "@/view/content/components/collapse/Collapse";
import AddressForm from "@/view/content/register-policy/steps/insurance-object/house/AddressForm";
export default {
  name: "House",
  components: { AddressForm, Collapse },
  props: ["v", "form", "state"],
  data() {
    return {
      isAddressChecking: false,
      chosenAddressId: -1,
      addresses: [
        {
          id: 1,
          street: "Kawalerzystów",
          houseNumber: "33",
          apartmentNumber: "7",
          postalCode: "53-004",
          city: "Wrocław"
        },
        {
          id: 2,
          street: "Józefa Piłsudskiego",
          houseNumber: "143a",
          apartmentNumber: "2",
          postalCode: "49-300",
          city: "Kędzierzyn-Koźle"
        }
      ]
    };
  },
  watch: {
    v: {
      handler() {
        this.$nextTick(() => {
          for (let item in this.v) {
            if (this.v[item].hasOwnProperty("$reset")) {
              if (this.v[item].hasOwnProperty("$each")) {
                for (let each in this.v[item].$each.$iter) {
                  let currentItem = this.v[item].$each[each];
                  for (let item2 in currentItem) {
                    if (currentItem[item2].hasOwnProperty("$reset")) {
                      if (
                        (currentItem[item2].$model !== "" &&
                          currentItem[item2].$model !== null) ||
                        (currentItem[item2].$dirty &&
                          !currentItem[item2].$error &&
                          currentItem[item2].$model === "" &&
                          currentItem[item2].$model === null)
                      ) {
                        currentItem[item2].$reset();
                      }
                    }
                  }
                }
              } else {
                if (
                  (this.v[item].$model !== "" &&
                    this.v[item].$model !== null) ||
                  (this.v[item].$dirty &&
                    !this.v[item].$error &&
                    this.v[item].$model === "" &&
                    this.v[item].$model === null)
                ) {
                  this.v[item].$reset();
                }
              }
            }
          }
        });
      },
      deep: true
    }
  },
  methods: {
    setIsAddressChecking(value) {
      this.isAddressChecking = value;
    },
    handleChangeAddress(address) {
      this.chosenAddressId = address.id;
      this.form.street = address.street;
      this.form.houseNumber = address.houseNumber;
      this.form.apartmentNumber = address.apartmentNumber;
      this.form.postalCode = address.postalCode;
      this.form.city = address.city;

      this.state.isAddNewVisible = false;
      this.state.isLoaded = null;
    },
    handleCollapseAddNewAddress() {
      this.state.isAddNewVisible = !this.state.isAddNewVisible;
      if (this.state.isAddNewVisible) {
        this.chosenAddressId = -1;
        this.form.street = "";
        this.form.houseNumber = "";
        this.form.apartmentNumber = "";
        this.form.postalCode = "";
        this.form.city = "";

        this.state.isLoaded = null;

        this.v.$reset();
      }
    }
  }
};
</script>
