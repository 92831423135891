<template>
  <div :id="id" :ref="id" class="collapse-wrapper">
    <div class="heading" @click="handleToggleCollapse">
      <div class="title" :class="titleColor !== '' && `text-${titleColor}`">
        <slot name="heading"></slot>
      </div>
      <div class="icon">
        <img
          :src="
            isCollapsed
              ? `/media/components/form/${collapsedIcon}.svg`
              : `/media/components/form/${notCollapsedIcon}.svg`
          "
          alt=""
        />
      </div>
    </div>
    <div
      ref="content"
      class="collapsed-content"
      :class="isCollapsed && animationFinished ? 'is-collapsed' : ''"
    >
      <slot name="content"></slot>
    </div>
  </div>
</template>

<script>
import {
  collapseSection,
  expandSection,
  updateSection
} from "@/core/utils/collapse";

export default {
  name: "Collapse",
  props: {
    collapsed: {
      type: Boolean,
      default: false
    },
    collapsedIcon: {
      type: String,
      default: "form-collapse-arrow-up"
    },
    notCollapsedIcon: {
      type: String,
      default: "form-collapse-arrow-down"
    },
    id: {
      type: String,
      default: ""
    },
    titleColor: {
      type: String,
      default: ""
    }
  },
  data() {
    return {
      isCollapsed: this.collapsed,
      animationFinished: false
    };
  },
  watch: {
    collapsed(newVal) {
      this.handleOnCollapse();
      this.isCollapsed = newVal;
    }
  },
  mounted() {
    this.$root.$on("updateSection", id => {
      this.$nextTick(() => {
        let item = document.getElementById(id);
        updateSection(item);
      });
    });
  },
  methods: {
    handleToggleCollapse() {
      this.$emit("onCollapse");
      this.handleCollapse();
    },
    handleOnCollapse() {
      this.handleCollapse();
    },
    handleCollapse() {
      const content = this.$refs.content;
      this.animationFinished = false;
      if (!this.isCollapsed) {
        this.$nextTick(() => {
          expandSection(content);
        });
      } else {
        this.$nextTick(() => {
          collapseSection(content);
        });
      }
      setTimeout(() => {
        this.animationFinished = true;
      }, 300);
    }
  }
};
</script>
