<template>
  <div data-wizard-type="step-content">
    <Modal
      size="lg"
      :opened="isSendEmailModalVisible"
      no-header
      save-text="Wyślij"
      @onClose="isSendEmailModalVisible = false"
      @onSave="handleSendEmail"
    >
      <p class="m-0 text-center" style="line-height: 2">
        Klient otrzyma wiadomość z linkiem do wyrażenia zgód na adres:<br />
        <span style="font-weight: 400">dummy@gmail.com</span>
      </p>
    </Modal>
    <Modal
      size="lg"
      :opened="isAddScanModalVisible"
      header-title="Wgrywanie skanu zgód RODO"
      @onClose="isAddScanModalVisible = false"
      @onSave="handleAddScan"
    >
      <div
        class="
          custom-file-upload-group
          d-flex
          align-items-center
          justify-content-center
        "
      >
        <button
          type="button"
          style="margin-right: 20px"
          class="btn btn-outline-primary-light"
        >
          Wybierz plik
        </button>
        <input
          type="text"
          class="form-control"
          name="scan-file-name"
          readonly
        />
        <input id="scanFile" type="file" name="scanFile" />
      </div>
    </Modal>
    <p class="m-0">
      Zgody marketingowe Ubezpieczającego - {{ client.firstName }}
      {{ client.lastName }}
    </p>
    <p
      class="text-secondary font-italic"
      style="margin-top: 20px; margin-bottom: 25px"
    >
      Możesz uzupełnić zgody klienta przez stronę www lub drukowany formularz z
      podpisami.
    </p>
    <div class="rodo-buttons">
      <button
        class="btn btn-primary-light btn mr-7"
        type="button"
        @click="handleOpenSendEmailModal"
      >
        Wyślij e-mail
      </button>

      <button
        class="btn btn-primary-light btn mr-7"
        type="button"
        :disabled="isPrintingForm"
        @click="handlePrintForm"
      >
        Drukuj formularz
        <i v-if="isPrintingForm" class="fa fa-spin fa-spinner ms-2"></i>
      </button>
      <button
        class="btn btn-outline-primary btn"
        type="button"
        @click="handleToggleAlreadyHaveRodoForm"
      >
        <template v-if="alreadyHaveRodoForm">Nie mam formularza</template>
        <template v-else>Mam już formularz</template>
      </button>
    </div>
    <div class="dashed-divider">&nbsp;</div>
    <table class="table table-custom-1 last-line">
      <thead>
        <tr>
          <th style="width: 0; padding: 0"></th>
          <th class="pl-0" style="width: 30px"></th>
          <th style="width: 440px"></th>
          <th style="width: 100px">Status</th>
          <th style="width: 140px; padding-left: 20px" class="text-center">
            Operacja
          </th>
        </tr>
      </thead>
      <tbody>
        <tr v-for="(agreement, index) in $v.agreements.$model" :key="index">
          <td style="width: 0; padding: 0">
            <RodoAgreementModal
              :agreements="agreements"
              :agreement="agreement"
              :index="index"
              :opened="
                isAgreementModalVisible && agreementModalVisibleIndex === index
              "
              :handle-close-agreement-modal="handleCloseAgreementModal"
              :v="$v"
            />
          </td>
          <td class="pl-0" style="width: 30px">
            <h5 class="m-0" style="font-size: 20px">{{ index + 1 }}</h5>
          </td>
          <td style="width: 440px" class="align-middle pr-5">
            <span v-html="agreement.text"></span>
          </td>
          <td style="width: 100px; font-size: 14px" class="align-middle">
            <template v-if="agreement.status === 'accepted'"
              ><div class="text-success">Wyrażona</div></template
            >
            <template v-if="agreement.status === 'notaccepted'"
              ><div class="text-danger">Niewyrażona</div></template
            >
            <template v-if="agreement.status === 'pending'"
              ><div class="text-primary">Nieustalona</div></template
            >
          </td>
          <td
            style="width: 140px; padding-left: 20px"
            class="align-middle text-center"
          >
            <template v-if="isActionsVisible || alreadyHaveRodoForm">
              <template v-if="agreement.status !== 'accepted'">
                <button
                  class="btn btn-outline-primary btn-sm"
                  type="button"
                  @click="handleOpenAgreementModal(index)"
                >
                  Dodaj zgodę
                </button>
              </template>
              <template v-else>
                <button
                  class="btn btn-outline-primary btn-sm"
                  type="button"
                  @click="handleOpenAgreementModal(index)"
                >
                  Edytuj zgodę
                </button>
              </template>
            </template>
          </td>
        </tr>
      </tbody>
    </table>
    <div class="text-end" style="margin-top: 20px; margin-right: 8px">
      <a
        v-if="isAddFormScanVisible || alreadyHaveRodoForm"
        href="#"
        class="text-primary"
        style="font-size: 13px; font-weight: 300"
        @click="handleOpenAddScanModal"
      >
        Dodaj skan formularza
      </a>
    </div>
  </div>
</template>

<script>
import Modal from "@/view/content/components/modal/Modal";
import { required } from "vuelidate/lib/validators";
import RodoAgreementModal from "@/view/content/register-policy/steps/rodo/RodoAgreementModal";

export default {
  name: "Rodo",
  components: { RodoAgreementModal, Modal },
  props: ["state", "form", "client"],
  data() {
    return {
      isAddFormScanVisible: false,
      alreadyHaveRodoForm: false,
      isPrintingForm: false,
      isActionsVisible: false,
      isAddScanModalVisible: false,
      isSendEmailModalVisible: false,
      isAgreementModalVisible: false,
      agreementModalVisibleIndex: -1,
      agreements: [
        {
          id: 0,
          text:
            "<span class='text-secondary'>Podstawowa zgoda</span> na przetwarzanie danych osobowych<br />przez Open Brokers S.A.",
          status: "notaccepted",
          obtainingMethod: "",
          signDate: "",
          informationObligation: false
        },
        {
          id: 1,
          text:
            'Zgoda na otrzymywanie od Open Brokers S.A. informacji handlowych<br />za pomocą środków komunikacji elektronicznej (<span class="text-secondary">email</span>/<span class="text-secondary">SMS</span>)',
          status: "notaccepted",
          obtainingMethod: "",
          signDate: "",
          informationObligation: false
        },
        {
          id: 2,
          text:
            'Zgoda na kontakt marketingowy <span class="text-secondary">przez telefon</span>/systemy <br />automatyczne',
          status: "notaccepted",
          obtainingMethod: "",
          signDate: "",
          informationObligation: false
        }
      ]
    };
  },
  validations() {
    return {
      agreements: {
        $each: {
          obtainingMethod: {
            required
          },
          signDate: {
            required
          }
        }
      }
    };
  },
  methods: {
    handleToggleAlreadyHaveRodoForm() {
      this.alreadyHaveRodoForm = !this.alreadyHaveRodoForm;
    },
    handlePrintForm() {
      this.isPrintingForm = true;
      setTimeout(() => {
        this.isActionsVisible = true;
        this.isAddFormScanVisible = true;
        this.isPrintingForm = false;
      }, 1000);
    },
    handleOpenAddScanModal() {
      this.isAddScanModalVisible = true;
    },
    handleOpenSendEmailModal() {
      this.isSendEmailModalVisible = true;
    },
    handleOpenAgreementModal(index) {
      this.isAgreementModalVisible = true;
      this.agreementModalVisibleIndex = index;
    },
    handleCloseAgreementModal() {
      this.isAgreementModalVisible = false;
      this.agreementModalVisibleIndex = -1;
    },
    handleSendEmail() {
      this.isSendEmailModalVisible = false;
      this.isActionsVisible = false;
      this.isAddFormScanVisible = false;
    },
    handleAddScan() {
      this.isAddScanModalVisible = false;
    }
  }
};
</script>
