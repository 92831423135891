<template>
  <fieldset :disabled="isClientChecking">
    <div class="d-flex align-items-center erasable-input-group">
      <div class="form-group" :class="isFieldInvalid('regon') && 'invalid'">
        <label for="regon">REGON</label>
        <input
          id="regon"
          v-model="form.regon"
          v-mask="'#########'"
          type="text"
          class="form-control"
          name="regon"
          @input="
            () => {
              if (state.isLoaded === true) {
                state.isLoaded = null;
              }
            }
          "
        />
        <div v-if="isFieldInvalid('regon')" class="invalid-feedback">
          Uzupełnij to pole
        </div>
      </div>
      <div v-if="form.regon !== ''" class="erase-icon">
        <img
          alt=""
          class="cursor-pointer"
          src="/media/components/form/form-erase.svg"
          @click="handleErase('regon')"
        />
      </div>
    </div>
    <SearchGroup
      :is-loaded="state.isLoaded"
      :is-loading="isClientChecking"
      error-icon="form-declined-fill"
      error-text="Uzupełnij dane klienta"
      success-icon="form-approved"
      success-text="Dane klienta zostały uzupełnione"
      additional-search
      @handleSearch="handleSearchClient"
    />
    <hr class="installments-divider" />
    <div class="row">
      <div class="col-lg-6">
        <div
          class="form-group"
          :class="isFieldInvalid('companyName') && 'invalid'"
        >
          <label for="companyName">Nazwa firmy</label>
          <input
            id="companyName"
            v-model="form.companyName"
            class="form-control"
            name="companyName"
            type="text"
          />
          <div v-if="isFieldInvalid('companyName')" class="invalid-feedback">
            Uzupełnij to pole
          </div>
        </div>
      </div>
    </div>
    <div class="row">
      <div class="col-lg-6">
        <div
          class="form-group"
          :class="(isFieldInvalid('nip') || isNipInvalid()) && 'invalid'"
        >
          <label for="nip">NIP</label>
          <input
            id="nip"
            v-model="form.nip"
            v-mask="'##########'"
            class="form-control"
            name="nip"
            type="text"
          />
          <div
            v-if="isFieldInvalid('nip') || isNipInvalid()"
            class="invalid-feedback"
          >
            <template v-if="isFieldInvalid('nip')">
              Uzupełnij to pole
            </template>
            <template v-else-if="isNipInvalid()">Nieprawidłowy NIP</template>
          </div>
        </div>
      </div>
    </div>
    <div class="row">
      <div class="col-lg-6">
        <div
          class="form-group"
          :class="
            (isFieldInvalid('phoneNumber') || isPhoneNumberInvalid()) &&
              'invalid'
          "
        >
          <label for="pesel">Numer telefonu</label>
          <div class="input-group">
            <button
              v-click-outside="hidePhonePrefix"
              class="btn btn-outline-gray-300 text-gray-900 px-2"
              type="button"
              @click.stop="openPhonePrefix = true"
            >
              {{ form.phoneNumberPrefix }}
              <img
                alt=""
                src="/media/components/form/form-angle-down.svg"
                style="margin-top: -2px; margin-left: 10px"
              />
            </button>
            <ul
              v-show="openPhonePrefix"
              class="dropdown-menu show"
              style="top: 35px"
            >
              <li>
                <div
                  class="dropdown-item cursor-pointer"
                  @click="
                    form.phoneNumberPrefix = '+48';
                    openPhonePrefix = false;
                  "
                >
                  +48
                </div>
              </li>
              <li>
                <div
                  class="dropdown-item cursor-pointer"
                  @click="
                    form.phoneNumberPrefix = '+49';
                    openPhonePrefix = false;
                  "
                >
                  +49
                </div>
              </li>
              <li>
                <div
                  class="dropdown-item cursor-pointer"
                  @click="
                    form.phoneNumberPrefix = '+50';
                    openPhonePrefix = false;
                  "
                >
                  +50
                </div>
              </li>
            </ul>
            <input
              id="phoneNumber"
              v-model="form.phoneNumber"
              v-mask="'###-###-###'"
              class="form-control"
              name="phoneNumber"
              type="text"
            />
          </div>

          <div
            v-if="isFieldInvalid('phoneNumber') || isPhoneNumberInvalid()"
            class="invalid-feedback"
          >
            <template v-if="isFieldInvalid('phoneNumber')">
              Uzupełnij to pole
            </template>
            <template v-else-if="isPhoneNumberInvalid()">
              Nieprawidłowy numer telefonu
            </template>
          </div>
        </div>
      </div>
    </div>
    <div class="d-flex align-items-center erasable-input-group">
      <div
        class="form-group mb-0"
        :class="(isFieldInvalid('email') || isEmailInvalid()) && 'invalid'"
      >
        <label for="email">E-mail</label>
        <input
          id="email"
          v-model="form.email"
          class="form-control"
          name="email"
          type="email"
        />
        <div
          v-if="isFieldInvalid('email') || isEmailInvalid()"
          class="invalid-feedback"
        >
          <template v-if="isFieldInvalid('email')">
            Uzupełnij to pole
          </template>
          <template v-else-if="isEmailInvalid()">
            Nieprawidłowy e-mail
          </template>
        </div>
      </div>
      <div v-if="form.email !== ''" class="erase-icon" style="margin-top: 25px">
        <img
          alt=""
          class="cursor-pointer"
          src="/media/components/form/form-erase.svg"
          @click="handleErase('email')"
        />
      </div>
      <div class="check-buttons" style="margin-top: 25px">
        <div class="check muted">
          <input
            :id="'withoutEmail-' + role"
            v-model="form.withoutEmail"
            :name="'withoutEmail-' + role"
            type="checkbox"
            @change="handleChangeWithoutEmail"
          />
          <label :for="'withoutEmail-' + role" class="text-muted"
            >Klient nie ma maila</label
          >
        </div>
      </div>
    </div>
  </fieldset>
</template>

<script>
import SearchGroup from "@/view/content/components/search-group/SearchGroup";

export default {
  name: "ClientCompanyForm",
  components: { SearchGroup },
  props: ["v", "form", "state", "role"],
  data() {
    return {
      openBirthDate: false,
      openBirthDateRole: -1,
      openPhonePrefix: false,
      isClientChecking: false
    };
  },
  watch: {
    v: {
      handler() {
        this.$nextTick(() => {
          for (let item in this.v) {
            if (this.v[item].hasOwnProperty("$reset")) {
              if (
                (this.v[item].$model !== "" && this.v[item].$model !== null) ||
                (this.v[item].$dirty &&
                  !this.v[item].$error &&
                  this.v[item].$model === "" &&
                  this.v[item].$model === null)
              ) {
                this.v[item].$reset();
              }
            }
          }
        });
      },
      deep: true
    }
  },
  methods: {
    resetClient() {
      this.form.regon = "";
      this.form.companyName = "";
      this.form.nip = "";
      this.form.phoneNumber = "";
      this.form.email = "";

      this.state.isLoaded = null;
    },
    hidePhonePrefix() {
      this.openPhonePrefix = false;
    },
    handleErase(field) {
      this.form[field] = "";
      if (field === "regon") {
        this.resetClient();
      }
    },
    isFieldInvalid(field) {
      return this.v[field].$dirty && this.v[field].$invalid;
    },
    isNipInvalid() {
      return !this.v.nip.isValid;
    },
    isPhoneNumberInvalid() {
      return !this.v.phoneNumber.minLength || !this.v.phoneNumber.maxLength;
    },
    isEmailInvalid() {
      return !this.v.email.isValid;
    },
    handleChangeWithoutEmail() {
      if (this.form.withoutEmail) {
        this.form.email = "";
      }
    },
    handleSearchClient() {
      this.isClientChecking = true;
      setTimeout(() => {
        if (this.form.regon === "111111111") {
          this.form.companyName = "Jan";
          this.form.nip = "3569791750";
          this.form.phoneNumber = "123 456 789";
          this.form.email = "nazwa.firmy@domena.pl";
          this.state.isLoaded = true;
        } else {
          this.state.isLoaded = false;
        }
        this.isClientChecking = false;
      }, 1000);
    }
  }
};
</script>
