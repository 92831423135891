<template>
  <div>
    <fieldset>
      <div class="row">
        <div class="col-lg-6">
          <div
            class="form-group"
            :class="isFieldInvalid('companyName') && 'invalid'"
          >
            <Select
              id="companyName"
              v-model="form.companyName"
              :options="companies"
              label="Nazwa firmy"
              name="companyName"
            />

            <div v-if="isFieldInvalid('companyName')" class="invalid-feedback">
              Uzupełnij to pole
            </div>
          </div>
        </div>
      </div>
      <div class="row">
        <div class="col-lg-6">
          <div class="form-group" :class="isFieldInvalid('regon') && 'invalid'">
            <label for="regon">REGON</label>
            <input
              id="regon"
              v-model="form.regon"
              v-mask="'#########'"
              type="text"
              class="form-control"
              name="regon"
            />
            <div v-if="isFieldInvalid('regon')" class="invalid-feedback">
              Uzupełnij to pole
            </div>
          </div>
        </div>
      </div>
      <div class="row">
        <div class="col-lg-6">
          <div
            class="form-group"
            :class="(isFieldInvalid('nip') || isNipInvalid()) && 'invalid'"
          >
            <label for="nip">NIP</label>
            <input
              id="nip"
              v-model="form.nip"
              v-mask="'##########'"
              type="text"
              class="form-control"
              name="nip"
            />
            <div
              v-if="isFieldInvalid('nip') || isNipInvalid()"
              class="invalid-feedback"
            >
              <template v-if="isFieldInvalid('nip')">
                Uzupełnij to pole
              </template>
              <template v-else-if="isNipInvalid()">Nieprawidłowy NIP</template>
            </div>
          </div>
        </div>
      </div>
      <div class="row">
        <div class="col-lg-6">
          <div class="form-group" :class="isFieldInvalid('city') && 'invalid'">
            <label for="city">Miejscowość</label>
            <input
              id="city"
              v-model="form.city"
              type="text"
              class="form-control"
              name="city"
            />
            <div v-if="isFieldInvalid('city')" class="invalid-feedback">
              Uzupełnij to pole
            </div>
          </div>
        </div>
      </div>
    </fieldset>
  </div>
</template>

<script>
import Select from "@/view/content/components/select/Select";
export default {
  name: "ClientLeasingOwnerForm",
  components: { Select },
  props: ["v", "form", "state", "policyType"],
  data() {
    return {
      companies: [
        { value: "firma-1", text: "Firma 1" },
        { value: "firma-2", text: "Firma 2" },
        { value: "firma-3", text: "Firma 3" }
      ]
    };
  },
  watch: {
    v: {
      handler() {
        this.$nextTick(() => {
          for (let item in this.v) {
            if (this.v[item].hasOwnProperty("$reset")) {
              if (
                (this.v[item].$model !== "" && this.v[item].$model !== null) ||
                (this.v[item].$dirty &&
                  !this.v[item].$error &&
                  this.v[item].$model === "" &&
                  this.v[item].$model === null)
              ) {
                this.v[item].$reset();
              }
            }
          }
        });
      },
      deep: true
    }
  },
  methods: {
    handleChangeLeasingCompany(event) {
      if (event.target.value === "firma-1") {
        this.form.regon = "111111111";
        this.form.nip = "1111111111";
        this.form.city = "Warszawa";
      } else {
        this.form.regon = "";
        this.form.nip = "";
        this.form.city = "";
      }
    },
    isNipInvalid() {
      return !this.v.nip.isValid;
    },
    isFieldInvalid(field) {
      return this.v[field].$dirty && this.v[field].$invalid;
    }
  }
};
</script>
