<template>
  <div class="custom-select">
    <label v-if="!hideLabel" :for="id !== '' ? id : name">{{ label }}</label>
    <input
      :id="id !== '' ? id : name"
      v-click-outside="handleClickOutside"
      class="form-select cursor-pointer"
      :name="name"
      :value="text"
      placeholder="Wybierz opcje"
      readonly
      @click="isVisible = !isVisible"
    />
    <div v-show="isVisible" class="select-content">
      <slot name="options">
        <div
          v-for="(option, index) in options"
          :key="index"
          class="select-item"
          @click="$emit('input', option.value)"
        >
          {{ option.text }}
        </div>
      </slot>
    </div>
  </div>
</template>

<script>
import _ from "lodash";
export default {
  name: "Select",
  props: {
    hideLabel: {
      type: Boolean,
      default: false
    },
    label: {
      type: String,
      default: ""
    },
    id: {
      type: String,
      default: ""
    },
    name: {
      type: String,
      default: ""
    },
    // eslint-disable-next-line vue/require-default-prop
    options: Array,
    value: {
      type: [String, Number],
      default: ""
    }
  },
  data() {
    return {
      isVisible: false,
      // text: "",
      text: this.value ? _.find(this.options, { value: this.value }).text : this.options.length
        ? this.options[0].text
        : ""
    };
  },
  watch: {
    value(newVal) {
      const find = _.find(this.options, { value: newVal });
      this.text = find.text;
      this.$emit("onChange");
    }
  },
  mounted() {
  },
  methods: {
    handleClickOutside() {
      this.isVisible = false;
    }
  }
};
</script>
