<template>
  <div style="margin-top: 30px">
    <Collapse
      id="collapse-add-children"
      :collapsed="state.insured.hasChildren"
      @onCollapse="handleCollapseChildren"
    >
      <template v-slot:heading>Chcę dodać dzieci do polisy</template>
      <template v-slot:content>
        <div style="margin-top: 30px">
          <div
            v-for="(child, index) in form.insured.children"
            :key="index"
            style="margin-top: 15px"
          >
            <h4 class="mb-2">{{ index + 1 }}</h4>
            <div class="row">
              <div class="col-lg-6">
                <div class="d-flex align-items-center">
                  <div
                    class="form-group"
                    style="margin-right: 50px"
                    :class="
                      isChildrenFieldInvalid(index, 'birthDate') && 'invalid'
                    "
                  >
                    <label :for="'child-birthDate' + index">
                      Data urodzenia dziecka
                    </label>
                    <Datepicker
                      :id="'child-birthDate' + index"
                      :form="child"
                      name="birthDate"
                      start-on="year"
                      :disabled-date="disabledBirthDate"
                      :opened="openBirthDate && openBirthDateIndex === index"
                      @openCalendar="handleOpenBirthDate"
                      @onChange="
                        handleChangeDate(
                          $event.target.value,
                          'birthDate',
                          index
                        )
                      "
                    />
                    <div
                      v-if="isChildrenFieldInvalid(index, 'birthDate')"
                      class="invalid-feedback"
                    >
                      Uzupełnij to pole
                    </div>
                  </div>
                  <img
                    style="margin-top: 5px"
                    class="cursor-pointer"
                    src="/media/components/form/form-remove-element.svg"
                    alt=""
                    @click="handleRemoveChildren(index)"
                  />
                </div>
              </div>
            </div>
            <div class="row">
              <div class="col-lg-6">
                <div
                  class="form-group"
                  :class="
                    isChildrenFieldInvalid(index, 'firstName') && 'invalid'
                  "
                >
                  <label :for="'firstName-' + index">Imię dziecka</label>
                  <input
                    :id="'firstName-' + index"
                    v-model="child.firstName"
                    type="text"
                    class="form-control"
                    name="firstName"
                  />
                  <div
                    v-if="isChildrenFieldInvalid(index, 'firstName')"
                    class="invalid-feedback"
                  >
                    Uzupełnij to pole
                  </div>
                </div>
              </div>
            </div>
            <div class="row">
              <div class="col-lg-6">
                <div
                  class="form-group"
                  :class="
                    isChildrenFieldInvalid(index, 'lastName') && 'invalid'
                  "
                >
                  <label :for="'lastName-' + index">Nazwisko dziecka</label>
                  <input
                    :id="'lastName-' + index"
                    v-model="child.lastName"
                    type="text"
                    class="form-control"
                    name="lastName"
                  />
                  <div
                    v-if="isChildrenFieldInvalid(index, 'lastName')"
                    class="invalid-feedback"
                  >
                    Uzupełnij to pole
                  </div>
                </div>
              </div>
            </div>
          </div>
          <img
            style="margin-top: 5px"
            class="cursor-pointer"
            src="/media/components/form/form-add-element.svg"
            alt=""
            @click="handleAddChildren"
          />
        </div>
      </template>
    </Collapse>
  </div>
</template>

<script>
import { scrollToElement } from "@/core/helpers/ScrollToElement";
import Collapse from "@/view/content/components/collapse/Collapse";
import { DateTime } from "luxon";
import Datepicker from "@/view/content/components/date-picker/Datepicker";
import { CONSTANTS } from "@/core/helpers/Constants";

export default {
  name: "ClientChildrenForm",
  components: { Collapse, Datepicker },
  props: ["v", "form", "state", "policyType", "handleSetCurrentStep"],
  data() {
    return {
      openBirthDate: false,
      openBirthDateIndex: -1
    };
  },
  computed: {
    constantsPolicyTypeAuto() {
      return CONSTANTS().policyTypeAuto;
    },
    constantsPolicyTypeProperty() {
      return CONSTANTS().policyTypeProperty;
    },
    constantsPolicyTypeLife() {
      return CONSTANTS().policyTypeLife;
    },
    disabledBirthDate() {
      return {
        min: null,
        max: DateTime.now()
      };
    }
  },
  watch: {
    "state.insured.hasChildren": function(newVal) {
      if (newVal) {
        if (this.form.insured.children.length < 1) {
          this.handleAddChildren();
          this.$nextTick(() => {
            scrollToElement(".children");
          });
        }
      }
    },
    v: {
      handler() {
        this.$nextTick(() => {
          for (let item in this.v) {
            if (this.v[item].hasOwnProperty("$reset")) {
              if (this.v[item].hasOwnProperty("$each")) {
                for (let each in this.v[item].$each.$iter) {
                  let currentItem = this.v[item].$each[each];
                  for (let item2 in currentItem) {
                    if (currentItem[item2].hasOwnProperty("$reset")) {
                      if (
                        (currentItem[item2].$model !== "" &&
                          currentItem[item2].$model !== null) ||
                        (currentItem[item2].$dirty &&
                          !currentItem[item2].$error &&
                          currentItem[item2].$model === "" &&
                          currentItem[item2].$model === null)
                      ) {
                        currentItem[item2].$reset();
                      }
                    }
                  }
                }
              } else {
                if (
                  (this.v[item].$model !== "" &&
                    this.v[item].$model !== null) ||
                  (this.v[item].$dirty &&
                    !this.v[item].$error &&
                    this.v[item].$model === "" &&
                    this.v[item].$model === null)
                ) {
                  this.v[item].$reset();
                }
              }
            }
          }
        });
      },
      deep: true
    }
  },
  methods: {
    handleChangeDate(value, field, index) {
      this.form.insured.children[index][field] = value
        ? DateTime.fromFormat(value, CONSTANTS().dateYmdFormat).toFormat(
            CONSTANTS().dateYmdFormat
          )
        : "";
    },
    isChildrenFieldInvalid(index, field) {
      return (
        this.v.children.$each[index][field].$dirty &&
        this.v.children.$each[index][field].$invalid
      );
    },
    handleCollapseChildren() {
      this.state.insured.hasChildren = !this.state.insured.hasChildren;
      if (!this.state.insured.hasChildren) {
        this.handleSetCurrentStep("insurer");
      }
    },
    handleAddChildren() {
      if (this.policyType === this.constantsPolicyTypeLife) {
        this.form.insured.children.push({
          birthDate: "",
          firstName: "",
          lastName: ""
        });
        this.$nextTick(() => {
          this.$root.$emit("updateSection", "collapse-add-children");
        });
      }
    },
    handleRemoveChildren(index) {
      this.form.insured.children.splice(index, 1);
      this.$nextTick(() => {
        this.$root.$emit("updateSection", "collapse-add-children");
      });
    },
    handleOpenBirthDate(index) {
      this.openBirthDate = true;
      this.openBirthDateIndex = index;
    }
  }
};
</script>
